import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthServices } from 'src/app/Services/auth.service';
import { TokenService } from 'src/app/Services/token.service';

@Component({
  selector: 'app-refer-earn',
  templateUrl: './refer-earn.component.html',
  styleUrls: ['./refer-earn.component.css']
})
export class ReferEarnComponent implements OnInit {

  constructor(
    private deviceService: DeviceDetectorService,
  ) { }
  public assetUrl = environment.assetUrl;
  hide = true;
  public imgSrc = this.assetUrl + 'images/general/man-img.png';
  public userDetails = JSON.parse(localStorage.getItem('userDetails'));
  public isMobile: boolean = this.deviceService.isMobile();

  ngOnInit() {

    this.imgSrc = this.userDetails.avatar;
  }
}
