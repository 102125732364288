import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiCallService } from '../Services/api-call.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  @ViewChild('ContactFormData') ContactFormData;

  constructor(
    private spinner: NgxSpinnerService,
    private ApiCall: ApiCallService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  public success = null;
  public countries = null;
  public selected_country = null;
  public error = null;
  public userDetails = JSON.parse(localStorage.getItem('userDetails'));

  ContactForm: UntypedFormGroup;
  submitted = false;

  public formData = {
    name: null,
    email: null,
    country: null,
    phone_code: null,
    phone: null,
    message: null,
  }

  ngOnInit() {

    this.ContactForm = this.formBuilder.group({
      'name': [null, Validators.required],
      'email': [null, [
        Validators.required,
        Validators.email
      ]],
      'phone_code': [],
      'phone': [null, Validators.required],
      'country': [null, Validators.required],
      'message': [null, Validators.required],
    });
    /** spinner starts on init */
    this.spinner.show();

    this.ApiCall.getCountries().subscribe(
      data => this.handleCountryResponse(data),
      error => this.handleCountryError(error)
    );
  }

  handleCountryResponse(res) {
    this.countries = res.data;
    for (let i = 0; i < this.countries.length; i++) {
      if (localStorage.getItem("user_details") != null) {
        if (this.countries[i]['id'] != this.userDetails.country_id) {
          continue;
        }
        this.selected_country = this.countries[i];
      }
    }
    if (this.userDetails) {
      this.formData.name = this.userDetails.first_name;
      this.formData.email = this.userDetails.email;
      this.formData.phone_code = this.userDetails.phone_code;
      this.formData.phone = this.userDetails.phone;
    }

    this.spinner.hide();
  }

  handleCountryError(error) {
    this.success = null;
    this.error = error.error.error;
    this.spinner.hide();
  }

  showUsaInfo() {
    Swal.fire({
      title: 'USA Customers',
      html: this.swalHtml(),
      showCancelButton: false,
      confirmButtonText: 'Ok, Got it!'
    })
  }

  swalHtml() {
    return `
      <p class="text-left">
        If you have any questions, issues, or complaints, please first contact PingMoney at:<br />
        Email Address: <a href="mailto:hello@pingmoney.co">hello@pingmoney.co</a><br />
        Phone no: <a href="tel:17862235388">+1-786-223-5388</a>
      </p>

      <p class="mt-2 mb-2 text-left">In case your grievances are not addressed by the PingMoney or for any
        escalation purposes, please contact Machnet in the following details Email Address: help@machnetinc.com
        or Phone no: +1-(408 539-6455)</p>
      <p class="mb-2 text-left">The banking services of the PingMoney are powered by Machnet. Machnet is a
        financial technology company and not a bank. Banking services are provided by Machnet's partner banks
        who are Member FDIC.</p>
      <p class="text-left">Machnet provides the Bank services through its banking software provider,
        Synapse. To report a complaint relating to the bank services, email help@synapsefi.com.</p>
    `
  }

  get f() { return this.ContactForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.ContactForm.invalid) {
      return;
    }
    this.spinner.show();
    this.formData = this.ContactForm.value;

    this.formData.phone_code = this.formData.country.calling_code;
    this.formData.country = this.formData.country.id;

    this.ApiCall.inquiry(this.formData).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );

  }

  handleResponse(data) {
    this.spinner.hide();
    this.ContactFormData.resetForm();
    this.success = "Your inquiry has been submitted successfully, our team will contact you ASAP"
  }

  handleError(error) {
    this.spinner.hide();
    this.error = error.error.error
  }



}
