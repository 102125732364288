<div class="send-money">
  <div class="row">
    <div class="col-md-5 col-md-push-7">
      <div class="money-transfer home">
        <!-- <h3 class="text-center">Money transfer</h3> -->

        <form [formGroup]="sendMoney" (ngSubmit)="onSubmit()" novalidate>

          <div class="form-group clearfix credit_wrapper">
            <div class="sending-box">
              <input matInput type="text" currencyFormatter numericType="decimal" id="sendingAmount"
                (input)="onChange('SEND')" formControlName="sending_amount" placeholder="100.00"
                [(ngModel)]="data.sending_amount">

              <mat-error *ngIf="submitted && f.sending_amount.errors" class="invalid-feedback">
                <div class="home_error" *ngIf="f.sending_amount.errors.minAmount">
                  Minimum send amount is {{ selectedSourceCountry?.currency_code }} {{ response?.rates.src_lowerlimit }}
                </div>
                <div class="home_error" *ngIf="f.sending_amount.errors.maxAmount">
                  Maximum send amount is {{ selectedSourceCountry?.currency_code }} {{ response?.rates.src_higherlimit
                  }}</div>
              </mat-error>

              <span>You are Sending</span>
            </div>
            <div mat-flat-button class="country-btn">
              <div class="dropdown credit_inner_btn">
                <button class="btn btn-secondary dropdown-toggle" tabindex="-1" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img loading="lazy" class="recharge-drop-img" src="{{ selectedSourceCountry?.flag }}" alt="">
                  <div class="currency_code_drop_wrapper">
                    <span class="currency_code"> {{ selectedSourceCountry?.currency_code }} </span>
                    <span class="country_name">{{ selectedSourceCountry?.name }}</span>
                  </div>
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <li class="dropdown-item" *ngFor="let source of source_country; let k = index"
                    (click)="SelectSourceCountry(source)">
                    <img loading="lazy" class="recharge-drop-img" src="{{ source?.flag }}" alt="">
                    {{ source?.currency_code }} -
                    <span>{{ source?.name }}</span>
                  </li>
                </ul>

              </div>
            </div>
          </div>

          <div class="form-group clearfix credit_wrapper">
            <div class="sending-box">
              <input matInput type="text" currencyFormatter numericType="decimal" (input)="onChange('RECEIVE')"
                id="receivingamount" formControlName="receiving_amount" placeholder="100.00"
                [(ngModel)]="data.receiving_amount">

              <mat-error *ngIf="submitted && f.receiving_amount.errors" class="invalid-feedback">
                <div *ngIf="f.receiving_amount.errors.required">Please enter amount</div>
                <div class="home_error" *ngIf="f.receiving_amount.errors.minAmount">
                  Minimum send amount is {{ selectedDestinationCountry?.currency_code }}
                  {{response?.rates.des_lowerlimit}}</div>
                <div class="home_error" *ngIf="f.receiving_amount.errors.maxAmount">
                  Minimum send amount is {{ selectedDestinationCountry?.currency_code }}
                  {{response?.rates.des_higherlimit}}</div>
              </mat-error>

              <span>They will Receive</span>
            </div>
            <div mat-flat-button class="country-btn">
              <div class="dropdown credit_inner_btn">
                <button class="btn btn-secondary dropdown-toggle" tabindex="-1" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img loading="lazy" class="recharge-drop-img" src="{{ selectedDestinationCountry?.flag }}" alt="">
                  <div class="currency_code_drop_wrapper">
                    <span class="currency_code"> {{ selectedDestinationCountry?.currency_code }} </span>
                    <span class="country_name">{{ selectedDestinationCountry?.name }}</span>
                  </div>
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <li class="dropdown-item" *ngFor="let destination of destination_country; let j = index"
                    (click)="SelectDestinationCountry(destination)">
                    <img loading="lazy" class="recharge-drop-img" src="{{ destination?.flag }}" alt="">
                    {{ destination?.currency_code }} -
                    <span> {{ destination?.name }} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="form-group clearfix ">

            <div class="rate-conversion" *ngIf="selectedSourceCountry?.currency_code">
              <i class="fas fa-exchange-alt"></i>
              <span> {{ selectedSourceCountry?.currency_code }} 1 =
                <label *ngIf="!disabled"> {{ selectedDestinationCountry?.currency_code }} {{ response?.rates.offerrate
                  }}</label>
                <i *ngIf="disabled" class="fas fa-circle-notch fa-spin place_loader"></i>
              </span>
            </div>
          </div>
          <div class="form-group">
            <div class="deposite-method">
              <label for="deposit">Delivery method</label>
              <div class="delivery_methods_wrap" data-toggle="buttons" *ngIf="!deliveryMethodPlaceholder">
                <label
                  [ngClass]="(method?.selected == 1)?'btn btn-primary delivery_methods_wrap_list active':'btn btn-primary delivery_methods_wrap_list'"
                  for="{{ method?.title }}_{{ method?.id }}" *ngFor="let method of delivery_methods; let k = index"
                  (click)="SelectDeliveryMethod(method)">
                  <input type="radio" id="{{ method?.title }}_{{ method?.id }}" name="delivery_method_id"
                    formControlName="delivery_method_id" [(ngModel)]="data.delivery_method_id"
                    *ngIf="method?.selected == 1" checked>
                  <input type="radio" id="{{ method?.title }}_{{ method?.id }}" name="delivery_method_id"
                    formControlName="delivery_method_id" [(ngModel)]="data.delivery_method_id"
                    *ngIf="method?.selected == 0">
                  <img loading="lazy" class="delivery_methods_wrap_img" src="{{ method?.image }}" alt="">
                  <span> {{ method?.text }} </span>
                </label>
              </div>

              <div class="placeholder-loading" *ngIf="deliveryMethodPlaceholder">
                <div class="info">
                  <div class="place img"></div>
                  <div class="details">
                    <div class="place name"></div>
                  </div>
                </div>
                <div class="info">
                  <div class="place img"></div>
                  <div class="details">
                    <div class="place name"></div>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-6">
              <div class="rate-conversion delivery_time" *ngIf="selectedSourceCountry?.currency_code">
                <i class="fas fa-calendar"></i> <span> Delivery Time = <label *ngIf="!disabled">{{
                    response?.rates.duration }}</label> <i *ngIf="disabled"
                    class="fas fa-circle-notch fa-spin place_loader"></i></span>
              </div>
            </div>
            <div class="col-md-6 fees_txt_container">
              <div class="rate-conversion">
                <i class="fas fa-money-bill"></i>
                <span *ngIf="response?.rates.fees != null"> Fee: <label *ngIf="!disabled"> {{
                    selectedSourceCountry?.currency_code }} {{ response?.rates.fees }} </label></span>
                <span *ngIf="response?.rates.fees == null"> Fee: <label *ngIf="!disabled"> N/A </label></span>
                <i *ngIf="disabled" class="fas fa-circle-notch fa-spin place_loader"></i>
              </div>
            </div>
          </div>

          <button mat-flat-button class="btn-style send-btn bg-blue" [disabled]="disabled" type="submit">Send
            Money</button>

        </form>
        <ul class="home-bill-payment-shortcut hidden-md hidden-lg">
          <li>
            <a routerLink="/pay-bills/electricity"><img loading="lazy" alt=""
                src="{{ assetUrl }}images/icons/electricity_w.png">Cash Power </a>
          </li>
          <li>
            <a routerLink="/pay-bills/mobile"><img loading="lazy" alt="" src="{{ assetUrl }}images/icons/mobile_w.png">
              Airtime </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-md-7 col-md-pull-5 hidden-xs">
      <div class="bg-section">
        <div class="bg-text">
          <h1 *ngIf="loadText"> Send money to {{ selectedDestinationCountry?.name }} </h1>
          <p>
            Delivering you fast, simple, and affordable <br/>money transfers with a smile. 
          </p>
          <button routerLink="/how-it-works" mat-flat-button class="bg-green">How it Works</button>
        </div>
      </div>
    </div>

  </div>
</div>

<section class="review section text-center">
  <div class="container">
    <h2 class="section-title">What our customers think</h2>
    <div class="row">
      <div id="carousel-inline-widget-810" style="width:100%;max-width:960px;margin:0 auto;"></div>
    </div>
  </div>
</section>

<section class="payout-partners section text-center" *ngIf="partnerLogos.length > 0">
  <div class="container">
    <h2 class="section-title" *ngIf="loadText">
      Where to receive money in {{ selectedDestinationCountry?.name }} from the {{ selectedSourceCountry?.name }}</h2>
    <div class="row" *ngIf="selectedDestinationCountry?.iso_3166_2 !== 'SN' && selectedDeliveryMethod?.name === 'CASH'">
      <h4>Receive at any of our payout partners </h4>
    </div>

    <div class="partner-logos-wrapper">
      <ng-contanier *ngFor="let logo of partnerLogos; let k = index">
        <div class="partner-logos-inner">
          <a routerLink="/agent-locations" [queryParams]="{ p: logo.title }">
            <img loading="lazy" [src]="logo.image" height="110px" alt="">
          </a>
        </div>
      </ng-contanier>
    </div>

    <div class="row" *ngIf="selectedDestinationCountry?.iso_3166_2 !== 'SN' && selectedDeliveryMethod?.name === 'BANK'">
      <div class="col-md-12 col-xs-12">
        <h4>Bank deposits to all major banks </h4>
      </div>
    </div>
    <div class="row partner-logo-wrapper"
      *ngIf="selectedDestinationCountry?.iso_3166_2 === 'SN' && selectedDeliveryMethod?.name === 'WALLET'">
      <div class="col-md-12">
        <h4>Mobile Wallets </h4>
      </div>
    </div>
  </div>
</section>
<section class="why-us section text-center hidden-xs">
  <div class="container">
    <h2 class="section-title">Why choose ping?</h2>
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <div class="why-us-reasons">
          <img loading="lazy" src="{{ assetUrl }}images/icons/simple.png" alt="">
          <h4>Easy to Use</h4>
          <p>We believe sending money should be stress-free, so we keep things simple.
          </p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="why-us-reasons">
          <img loading="lazy" src="{{ assetUrl }}images/icons/low_cost.png" alt="">
          <h4>Low Cost</h4>
          <p>Our fees are competitively low and we offer excellent exchange rates.
          </p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="why-us-reasons">
          <img loading="lazy" src="{{ assetUrl }}images/icons/fast_service.png" alt="">
          <h4>Fast Service</h4>
          <p>We make sure your loved ones get their money when they need it.
          </p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="why-us-reasons last-margin">
          <img loading="lazy" src="{{ assetUrl }}images/icons/trusted.png" alt="">
          <h4>Trusted & Secure</h4>
          <p>You can trust us with your money and personal data.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="send-money-steps send-money hidden-xs">
  <div class="container">
    <div class="row send-money-steps-outer">
      <div class="col-md-6">
        <div class="money-transfer">
          <h2 class="title">
            As easy as 1, 2, ping! Send money in 3 easy steps
          </h2>
          <div class="steps clearfix">
            <div class="image">
              <img loading="lazy" src="{{ assetUrl }}images/icons/amount_select.png" alt="">
            </div>
            <div class="steps-text">
              <h4>Sending Amount</h4>
              <p>
                Enter the amount you wish to send and see the total <br>receipt amount (all fees included)
              </p>
            </div>
          </div>

          <div class="steps clearfix">
            <div class="image">
              <img loading="lazy" src="{{ assetUrl }}images/icons/recipient.png" alt="">
            </div>
            <div class="steps-text">
              <h4>Select Recipients</h4>
              <p>Choose the recipient or enter new recipient details</p>
            </div>
          </div>

          <div class="steps clearfix">
            <div class="image">
              <img loading="lazy" src="{{ assetUrl }}images/icons/payment.png" alt="">
            </div>
            <div class="steps-text">
              <h4>Make Payment</h4>
              <p>Choose how to pay by either by Opening Banking, <br> Bank transfer or Debit/Credit card</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <img class="img-responsive send-money-steps-image" loading="lazy" src="{{ assetUrl }}images/general/steps_back.png" alt="">
      </div>

    </div>
  </div>
</div>


<section class="safety section hidden-xs">
  <div class="container">
    <h2 class="section-title text-center">Safety is our Priority</h2>
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="safety-type clearfix">
          <img loading="lazy" src="{{ assetUrl }}images/icons/AML.png" alt="">
          <div class="box">
            <h4>Anti money laundering</h4>
            <p>Operating under strict HM Revenue and Customs Anti-Money Laundering regulation.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-6">
        <div class="safety-type clearfix">
          <img loading="lazy" src="{{ assetUrl }}images/icons/ssl.png" alt="">
          <div class="box">
            <h4>SSL secured payments</h4>
            <p>Rest assured that all transactions are handled over secure connections. Ping never stores your
              payment data.
              <!-- Data is directly sent to our PCI-DSS compliant payment gateway service provider -->
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-6">
        <div class="safety-type clearfix last third">
          <img loading="lazy" src="{{ assetUrl }}images/icons/regulation.png" alt="">
          <div class="box">
            <h4>FCA Regulated</h4>
            <p>Ping is registered and regulated by the Financial Conduct Authority (Financial Services
              Registration No: 799928).
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-6">
        <div class="safety-type clearfix last-margin last">
          <img loading="lazy" src="{{ assetUrl }}images/icons/two_way.png" alt="">
          <div class="box">
            <h4>2-Way authentication</h4>
            <p>Security with one-time password authentication for extra peace of mind.
          </div>
        </div>
      </div>


    </div>
  </div>
</section>