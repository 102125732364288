import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ApiCallService } from '../Services/api-call.service';
import { Observable, timer } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.css', './email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {

  constructor(
    private ApiCall: ApiCallService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
  ) { }

  public assetUrl = environment.assetUrl;
  public userDetails = JSON.parse(localStorage.getItem('userDetails'));
  public confirmationMessage: string = "";
  public emailUpdateMessage: string = "Please provide an email address to update.";
  public otpForm: UntypedFormGroup;
  public emailForm: UntypedFormGroup;
  public submitted = false;
  public counter: Observable<number>;
  public count = 30;
  public showResend: Boolean = false;
  public showEmailUpdate: Boolean = false;

  public form = {
    otp: null
  }

  public emailFormData = {
    email: this.userDetails?.email ?? null,
  }

  get f() { return this.otpForm.controls; }
  get emailFormControl() { return this.emailForm.controls; }

  ngOnInit(): void {
    this.resendVerifyEmail();
    this.setOtpValidations();
  }

  setOtpValidations() {
    this.otpForm = this.formBuilder.group({
      'otp': [this.form.otp, [Validators.required]]
    });
  }

  setEmailValidations() {
    this.emailForm = this.formBuilder.group({
      'email': [this.emailFormData.email, [Validators.email, Validators.required]]
    });
  }

  onSetUpdateEmail() {
    this.showEmailUpdate = true
    this.setEmailValidations()
  }

  resendVerifyEmail() {
    this.spinner.show();
    this.ApiCall.resendVerifyEmail().subscribe(
      data => this.handleEmailResponse(data),
      error => this.handleEmailError(error)
    );
  }

  handleEmailResponse(res): void {
    this.spinner.hide();
    this.confirmationMessage = res.message
    if (!res.success) {
      this.onSetUpdateEmail();
      this.toastr.error(res.message, 'Error !', {
        closeButton: true,
        positionClass: 'toast-bottom-center',
      });
      return
    }
    this.toastr.success(res.message, 'Success !', {
      closeButton: true,
      positionClass: 'toast-bottom-center',
    });
  }

  handleEmailError(error): void {
    this.spinner.hide();

  }

  runTimer(): void {
    this.showResend = false;
    this.counter = timer(0, 1000).pipe(
      take(this.count),
      tap(progress => {
        if (this.count == 1) {
          this.showResend = true;
          this.count = 30;
        }
      }),
      map(() => --this.count)
    );
  }

  onSubmit() {
    this.submitted = true;

    if (!this.form.otp) {
      this.toastr.error("Please fill the complete OTP.", 'Error !', {
        closeButton: true,
        positionClass: 'toast-bottom-center',
      });

      return;
    }

    if (this.form.otp && this.form.otp.length != 4) {
      this.toastr.error("Please fill the complete OTP.", 'Error !', {
        closeButton: true,
        positionClass: 'toast-bottom-center',
      });

      return;
    }

    this.spinner.show();

    this.ApiCall.verifyEmailOTP(this.form).subscribe(
      data => this.handleSubmitResponse(data),
      error => this.handleSubmitError(error)
    );
  }

  handleSubmitResponse(res): void {
    this.spinner.hide();
    this.userDetails['isEmailVerified'] = true;
    localStorage.setItem("userDetails", JSON.stringify(this.userDetails));
    this.router.navigateByUrl('account/profile');
  }

  handleSubmitError(error): void {
    this.spinner.hide();
    this.toastr.error(error.error.message, 'Error !', {
      closeButton: true,
      positionClass: 'toast-bottom-center',
    });
  }

  onEmailUpdate() {
    this.submitted = true;

    if (this.emailForm.invalid) {
      return;
    }

    this.spinner.show();

    this.ApiCall.updateEmail(this.emailFormData).subscribe(
      data => this.handleEmailUpdateResponse(data),
      error => this.handleEmailUpdateError(error)
    );
  }

  handleEmailUpdateResponse(res): void {
    this.spinner.hide();
    this.showEmailUpdate = false
    this.toastr.success(res.message, 'Success !', {
      closeButton: true,
      positionClass: 'toast-bottom-center',
    });

    this.confirmationMessage = res.message
    this.userDetails['isEmailVerified'] = false;
    this.userDetails['email'] = this.emailFormData.email;
    localStorage.setItem("userDetails", JSON.stringify(this.userDetails));
  }

  handleEmailUpdateError(error): void {
    this.spinner.hide();

    if (error.status === 422) {
      const validationErrors = error.error;

      for (let [field, message] of Object.entries(validationErrors)) {
        const formControl = this.emailForm.get(field);
        if (formControl) {
          formControl.setErrors({
            serverError: message[0]
          });
        }
      }

    } else {
      this.toastr.error(error.error.message, 'Error !', {
        closeButton: true,
        positionClass: 'toast-bottom-center',
      });
    }

  }

  onPinChange(val) {
    this.form.otp = val
  }
}
