<svg version="1.1"
    attr.height="{{ getHeight() }}"
    attr.width="{{ getWidth() }}"
    x="0px" 
    y="0px"    
	viewBox="0 0 64 64"
    style="enable-background:new 0 0 64 64;" 
    xml:space="preserve">
    <g>
        <path [ngStyle]="{'fill': getPrimaryColor()}"  class="st0" d="M60.3,38.3c0-3.7-2.6-6.2-6.2-6.2c-3,0-6.1,0-9.1,0c-0.5,0-0.6-0.1-0.6-0.6c0-7,0-14.1,0-21.1
            C44.3,6.6,41.7,4,38,4c-9.1,0-18.2,0-27.3,0c-0.4,0-0.8,0-1.1,0.1C6.4,4.6,4.3,7,4.3,10.2c0,6.7,0,13.3,0,20c0,6.6,0,13.2,0,19.8
            c0,5.7,4.4,10.1,10.1,10.1c11.9,0,23.8,0,35.7,0c5.7,0,10.1-4.4,10.1-10.1C60.3,46,60.3,42.2,60.3,38.3z M41.6,56.1
            c-8.9,0-17.9,0-26.8,0c-3.9,0-6.4-2.5-6.4-6.4c0-13.1,0-26.2,0-39.3C8.3,8.8,9.1,8,10.7,8C19.8,8,28.9,8,38,8
            c1.6,0,2.3,0.8,2.3,2.3c0,13,0,26.1,0,39.1c0,2.4,0.5,4.6,2,6.6C42,56.1,41.8,56.1,41.6,56.1z M56.3,50c0,3.4-2.7,6.1-6,6.1
            c-3.3,0-6-2.7-6-6.1c0-2.2,0-4.3,0-6.5c0-2.3,0-4.6,0-6.9c0-0.4,0.1-0.5,0.5-0.5c3.1,0,6.2,0,9.3,0c1.3,0,2.2,0.8,2.2,2.1
            C56.3,42.1,56.3,46,56.3,50z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}"  class="st1" d="M24.3,30c-3.3,0-6.7,0-10,0c-1,0-1.9-0.8-2-1.8c-0.1-1,0.5-1.9,1.5-2.2c0.2,0,0.4-0.1,0.6-0.1
            c6.6,0,13.2,0,19.8,0c1.1,0,2,0.8,2.1,1.8c0.1,1-0.6,2-1.6,2.2c-0.2,0.1-0.5,0-0.7,0C30.8,30,27.5,30,24.3,30z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}"  class="st1" d="M24.3,38c-3.3,0-6.6,0-9.9,0c-1.6,0-2.6-1.6-1.8-3c0.4-0.7,1-1,1.8-1c6.6,0,13.3,0,19.9,0c1.2,0,2,0.9,2,2
            c0,1.1-0.9,2-2.1,2C30.9,38,27.6,38,24.3,38z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}"  class="st1" d="M29.3,46c-1.6,0-3.2,0-4.8,0c-1.3,0-2.2-0.8-2.2-2c0-1.2,0.9-2,2.2-2c3.2,0,6.4,0,9.6,0c1.3,0,2.2,0.8,2.2,2
            c0,1.2-0.9,2-2.2,2C32.5,46,30.9,46,29.3,46z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}"  class="st1" d="M18.3,16c-1.3,0-2.5,0-3.8,0c-1.2,0-2.1-0.8-2.1-2c0-1.2,0.9-2,2.1-2c2.6,0,5.2,0,7.7,0c1.2,0,2.1,0.8,2.1,2
            c0,1.2-0.9,2-2.1,2C20.9,16,19.6,16,18.3,16z"/>
    </g>
</svg>
