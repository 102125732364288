import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {

  constructor(
    private deviceService: DeviceDetectorService) { }
    
  public assetUrl = environment.assetUrl;
  public isMobile:boolean =  this.deviceService.isMobile();

  ngOnInit(): void {
  }

}
