<div class="container pay-bills">
    <section class="section box">
        <div class="row">
            <div class="col-md-3" *ngIf="!isMobile">
                <app-wallet-nav-tabs></app-wallet-nav-tabs>
            </div>
            <div class="col-md-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </section>
    <div class="floating-wallet-menu-wrapper" *ngIf="isMobile">
        <ul class="inner-menu">
            <li routerLinkActive="active">
                <a routerLink="/wallet/overview">
                    <img src="{{ assetUrl }}images/icons/wallet/overview-outline.svg" alt="">
                </a>
                <span class="mt-1 text-center">Overview</span>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/wallet/transactions">
                    <img src="{{ assetUrl }}images/icons/wallet/transactions-outline.svg" alt="">
                </a>
                <span class="mt-1 text-center">Transactions</span>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/wallet/send-money">
                    <img src="{{ assetUrl }}images/icons/wallet/send-outline.svg" alt="">
                </a>
                <span class="mt-1 text-center">Send</span>
            </li>
            <!-- <li routerLinkActive="active">
                <a routerLink="/wallet/request-money">
                    <img src="{{ assetUrl }}images/icons/wallet/request-outline.svg" alt="">
                </a>
                <span>Request</span>
            </li> -->
            <li routerLinkActive="active">
                <a routerLink="/wallet/topup">
                    <img src="{{ assetUrl }}images/icons/wallet/deposit-outline.svg" alt="">
                </a>
                <span class="mt-1 text-center">Add</span>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/wallet/withdrawal-money">
                    <img src="{{ assetUrl }}images/icons/wallet/withdrawal-outline.svg" alt="">
                </a>
                <span class="mt-1 text-center">Withdraw</span>
            </li>
        </ul>
    </div>
</div>