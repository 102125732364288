<svg version="1.1"
    attr.height="{{ getHeight() }}"
    attr.width="{{ getWidth() }}"
    x="0px" 
    y="0px"    
	viewBox="0 0 64 64"
    style="enable-background:new 0 0 64 64;" 
    xml:space="preserve">
    <g>
        <path [ngStyle]="{'fill': getPrimaryColor()}" d="M40.4,60c-0.6-0.2-1.3-0.3-2-0.5c-3.5-1.3-5.9-4.9-5.7-8.8c0.2-3.7,2.9-7,6.4-8c0.9-0.2,1.8-0.3,2.6-0.3
            c3.7,0,7.5,0,11.2,0c0.2,0,0.4,0,0.5,0c0-0.2-0.1-0.3-0.2-0.3c-0.7-0.7-1.5-1.4-2.2-2.2c-0.9-0.9-0.9-2.2,0-3.1
            c0.8-0.9,2.1-0.9,3-0.1c1.5,1.4,2.9,2.8,4.3,4.2c0.9,0.9,1.3,2,1.5,3.2c0,0.4,0,0.7,0,1.1c0,1.7-1,2.9-2.1,4
            c-1.1,1-2.1,2.1-3.2,3.2c-0.7,0.7-1.5,1.1-2.5,0.8c-1.5-0.4-2.2-2.3-1.3-3.6c0.6-0.9,1.5-1.5,2.2-2.3c0.1-0.1,0.3-0.2,0.4-0.4
            c-0.1-0.1-0.2-0.1-0.3-0.1c-3.8,0-7.6,0-11.4,0c-1.6,0-2.9,0.6-3.8,2c-1.6,2.5-0.2,5.7,2.6,6.4c0.4,0.1,0.8,0.1,1.2,0.2
            c0.9,0.1,1.5,0.6,1.9,1.4c0.4,0.8,0.2,1.6-0.3,2.4C43,59.7,42.5,59.9,42,60C41.4,60,40.9,60,40.4,60z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" d="M45.9,33c-1-0.6-2.1-1.1-3.1-1.5c-0.6-0.2-1.2-0.4-1.8-0.7c0.3-0.3,0.6-0.5,0.9-0.8c1.9-1.7,3.3-3.7,4.1-6
            c1.3-3.6,1.3-7.2-0.2-10.7c-1.9-4.5-5.1-7.4-9.8-8.7c-0.8-0.2-1.7-0.4-2.6-0.6c-0.1,0-0.2,0-0.3,0C33,4.1,33,4.1,32.9,4.1
            c-0.1,0-0.2,0-0.2-0.1c-0.4,0-0.9,0-1.3,0c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1c-0.2,0-0.4,0-0.5,0
            c-0.4,0.2-0.9,0.1-1.3,0.2c-4.7,1-8.2,3.7-10.4,7.9c-1.4,2.7-1.9,5.6-1.5,8.7c0.3,2,0.9,3.9,1.9,5.7c0.9,1.5,2,2.8,3.4,4
            c0.4,0.3,0.4,0.3-0.1,0.5c-1.3,0.5-2.7,1.1-3.9,1.8c-3.2,1.7-5.9,3.9-8.2,6.7C7,43.2,4.8,47.5,4,52.5c0,0.6,0,1.2,0,1.9
            c0.1,0.4,0.1,0.8,0.2,1.2c1,2.5,2.7,4.1,5.4,4.5c6.2,0,12.3,0,18.5,0c0.1-0.1,0.3-0.1,0.4-0.2c1.1-0.5,1.7-1.8,1.2-2.9
            c-0.4-0.9-1.2-1.3-2.2-1.3c-5.5,0-11,0-16.5,0c-0.3,0-0.5,0-0.8-0.1C9,55.3,8.2,54.2,8.4,53c0.3-1.5,0.7-2.9,1.3-4.3
            c1.8-4.2,4.5-7.7,8.2-10.3c4.1-3,8.7-4.5,13.7-4.6c4.2-0.1,8.1,0.9,11.8,2.9c0.4,0.2,0.8,0.4,1.2,0.4c1,0,1.9-0.6,2.2-1.5
            C47.2,34.6,46.8,33.5,45.9,33z M32.2,29.4c-5.9,0-10.7-4.7-10.7-10.5c0-5.8,4.7-10.5,10.5-10.5c5.8,0,10.5,4.7,10.5,10.5
            C42.5,24.6,37.8,29.4,32.2,29.4z"/>
    </g>
</svg>