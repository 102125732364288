import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class BeforeLoginService implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean |
  Observable<boolean> | Promise<boolean>
  {
    // throw new Error("Method not implemented.");
    
    if (!this.Token.loggedIn()) { return !this.Token.loggedIn(); } 
    
    this.router.navigate(['/']);
  }
  constructor(
    private Token: TokenService,
    private router: Router
  ) { }
}
