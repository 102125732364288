<svg version="1.1"
    attr.height="{{ getHeight() }}"
    attr.width="{{ getWidth() }}"
    x="0px" 
    y="0px"    
	viewBox="0 0 64 64"
    style="enable-background:new 0 0 64 64;" 
    xml:space="preserve">
    <g>
        <path [ngStyle]="{'fill': getPrimaryColor()}" d="M49.8,60c-0.3,0-0.6-0.1-0.9-0.2c-0.8-0.2-1.2-0.9-1.2-1.8c0-1.8,0-3.7,0-5.5c0-0.2,0-0.4-0.2-0.6
            c-0.1-0.1-0.3-0.2-0.5-0.2l0,0c-0.9,0-1.9,0-2.9,0c-0.9,0-1.7,0-2.6,0c-1,0-1.9-0.7-2-1.7c-0.2-0.9,0.4-1.8,1.2-2.2
            c0.3-0.1,0.7-0.2,1-0.2c0.3,0,0.6,0,0.9,0l2,0c0.7,0,1.6,0,2.5,0l0,0c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.1-0.3,0.1-0.5
            c0-1.9,0-3.8,0-5.6c0-0.8,0.4-1.4,1.1-1.8c0.3-0.2,0.6-0.2,0.9-0.2c0.4,0,0.7,0.1,1.1,0.3c0.6,0.4,0.9,1,0.9,1.8c0,0.6,0,1.2,0,1.8
            c0,1.2,0,2.4,0,3.6c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0,0,0.1,0c0.3,0,0.7,0,1.2,0c0.3,0,0.5,0,0.8,0
            c0.3,0,0.5,0,0.8,0c0.3,0,0.6,0,0.8,0c0.3,0,0.6,0,0.8,0c0.3,0,0.7,0,1,0c1.2,0,2.1,0.9,2.1,2c0,0.5-0.2,1-0.6,1.4
            c-0.4,0.4-0.9,0.6-1.6,0.6c-0.9,0-1.8,0-2.6,0c-1,0-1.9,0-2.8,0l0,0c-0.2,0-0.4,0-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.6
            c0,1.2,0,2.3,0,3.5c0,0.6,0,1.3,0,1.9c0,0.7-0.2,1.2-0.7,1.6C50.7,59.8,50.3,60,49.8,60z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" d="M10.2,59.9c-2.3,0-4.1-1.1-5.4-3.3c-0.4-0.7-0.6-1.4-0.7-2.1l0,0l0,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.4
            v-1.1c0.4-1.5,0.8-2.7,1.4-3.7c2.3-4.7,6-8.1,11-10.2c0.1,0,0.2-0.1,0.4-0.2l0.5-0.2l-0.2-0.2c-1-1-1.8-2-2.4-3.3
            c-1.3-2.5-1.5-5.5-0.6-8.3c0.9-2.8,2.8-5.1,5.4-6.3c1.6-0.8,3.4-1.2,5.1-1.2c4.3,0,8.2,2.5,10,6.5c0,0,0,0.1,0,0.1
            c0,0.1,0.1,0.3,0.4,0.3c0.1,0,0.2,0,0.3-0.1c1.3-0.9,2.8-1.7,4.5-2.4l0.2-0.1l-0.2-0.2c-2.7-2.6-3.8-5.7-3.5-9.3
            c0.3-2.9,1.6-5.4,3.9-7.4c1.9-1.7,4.4-2.6,7-2.6c3,0,5.7,1.1,7.8,3.2c2.1,2.1,3.3,5,3.3,7.9c0,3-1.3,5.9-3.6,8.1l-0.2,0.2l0.3,0.1
            c1,0.4,1.9,0.8,2.7,1.3l0.2,0.1c0.4,0.2,0.8,0.5,1.2,0.8c1,0.7,1.3,1.9,0.7,2.8c-0.4,0.6-1,0.9-1.7,0.9c0,0,0,0,0,0
            c-0.4,0-0.8-0.1-1.2-0.4c-2.3-1.5-4.5-2.4-6.8-2.8c-1-0.1-1.9-0.2-2.8-0.2c-3.3,0-6.4,1-9.3,2.9c-0.6,0.4-1.3,0.9-2,1.6
            c-0.2,0.2-0.3,0.4-0.4,0.7c-0.3,2.6-1.4,4.9-3.4,6.8c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0.1l-0.1,0.2c0,0,0.6,0.3,0.8,0.4
            c0.4,0.2,0.8,0.4,1.2,0.6c0.8,0.4,1.6,0.9,2.4,1.4c1,0.7,1.2,1.9,0.6,2.8c-0.4,0.6-1,0.9-1.7,0.9c-0.4,0-0.8-0.1-1.1-0.3
            c-2-1.3-3.7-2.1-5.5-2.5c-1.5-0.3-2.9-0.5-4.3-0.5c-3.6,0-7,1.2-10.2,3.4c-2.7,2-4.6,4.6-5.8,7.8C8,53.4,8,54.3,8.5,55
            c0.5,0.7,1.3,1,2.3,1l11,0c2.5,0,5.1,0,7.6,0c1.1,0,1.9,0.6,2.2,1.5c0.2,0.6,0,1.2-0.3,1.7c-0.4,0.5-0.9,0.7-1.5,0.7
            c-1.9,0-3.7,0-5.6,0c-1.9,0-3.7,0-5.6,0C15.8,59.9,13,59.9,10.2,59.9L10.2,59.9z M24.5,23.1c-3.8,0-7,3.1-7,7c0,3.9,3.1,7,7,7
            l0.1,0c3.8,0,7-3.1,7-7C31.5,26.3,28.4,23.2,24.5,23.1L24.5,23.1z M47.3,8.1c-3.9,0-7,3.1-7,7c0,1.9,0.7,3.6,2.1,4.9
            c1.3,1.3,3.1,2.1,4.9,2.1l0,0c3.8,0,7-3.2,7-7C54.3,11.2,51.1,8.1,47.3,8.1L47.3,8.1z"/>
    </g>
</svg>