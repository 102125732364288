
<!-- recipients-section starts-->
<section class="section legal-section">
    <div class="container">
      <div class="row">
  
        <div class="col-md-12">            
            <mat-card class="setting-card">
                <div class="card-body text-center">
                    <div class="card-notification">
                        <mat-card-title class="text-center">Campaign unsubscribe</mat-card-title>
                        <img alt="" src="{{ assetUrl }}images/general/mail_campaign.png">
                        <p class="text-center mt-3"> Do you really want to unsubcribe from the campaign list , You will <br> miss the important updates from us</p>
                        <br>
                        <button (click)="unsubcribeCampaign()" *ngIf="!buttonHidden" mat-flat-button class="btn-style bg-blue continue-btn mb-4"> Continue unsubcribe </button>
                    </div>
                </div>
            </mat-card>
        </div>   
      </div>
    </div>
  </section>
  
  