import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { AuthServices } from '../Services/auth.service';
import { UtilService } from '../Services/util.service';
import { Router } from '@angular/router';
import { TokenService } from '../Services/token.service';
import { AvatarService } from '../Services/avatar.service';
import { Observable } from 'rxjs';
import { HideHeaderService } from '../Services/hide-header.service';
import { environment } from 'src/environments/environment';


declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  public loggedIn: boolean;
  public user_id = null;
  public email = null;
  public name = null;
  public avatar = this.assetUrl + 'images/general/man-img.png';
  public userDetails = null;
  public error: 'null';
  public _opened: boolean = false;
  public _opened_second_level: boolean = false;
  public _MODES: string = 'over';
  public _closeOnClickOutside: boolean = true;
  public _closeOnClickBackdrop: boolean = true;
  public _showBackdrop: boolean = true;
  public showKycBanner: boolean = false;


  constructor(
    private Auth: AuthServices,
    private router: Router,
    private Token: TokenService,
    private el: ElementRef,
    private renderer: Renderer2,
    private AvatarService: AvatarService,
    private headerService: HideHeaderService,
    private utils: UtilService,
  ) {
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  _toggleSecondSidebar() {
    this._opened_second_level = !this._opened_second_level;
  }

  _closeBothSidebar() {
    this._opened = false;
    this._opened_second_level = false;
    if (this.router.url == '/auth/login' || this.router.url == '/auth/signup') {
      this.headerService.setHeaderVisibility(false);
    }
  }

  ngOnInit() {
    this.Auth.authStatus.subscribe(value => this.loggedIn = value);

    // dropdown style
    $('.navbar .dropdown').hover(function () {
      $(this).find('.dropdown-menu').first().stop(true, true).delay(150).fadeIn(250);
    }, function () {
      $(this).find('.dropdown-menu').first().stop(true, true).delay(100).fadeOut(250)
    });
    // dropdown style
    if (this.Token.loggedIn()) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.avatar = this.userDetails.avatar;
      this.AvatarService.userReplaySubject.subscribe(user => {
        this.avatar = user['avatar'];
      })

      if (this.userDetails?.kyc != '1') {
        this.utils.setIsKYCBannerVisible(true);
        this.utils.getIsKYCBannerVisible.subscribe(item => this.showKycBanner = item);
      }
    }

  }

  isLoggedIn() {
    return this.Token.loggedIn();
  }

  showKycBannerOnRoute() {
    return this.router.url.includes('/account/verification/') ? false : true;
  }

  onMenuClick() {
    // this.renderer.setElementClass(this.el.nativeElement.querySelector('.navbar-collapse'), 'in', false);  
    this._opened = !this._opened;
  }

  logout(event: MouseEvent) {
    event.preventDefault();
    this.Token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/auth/login');
    this._closeBothSidebar();
  }

}
