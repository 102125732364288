import { Component, OnInit, HostListener, ElementRef, Directive, Renderer2, Inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiCallService } from '../Services/api-call.service';
import { UntypedFormGroup,UntypedFormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServices } from '../Services/auth.service';
import { Title } from '@angular/platform-browser';
let lang = localStorage.getItem('language');
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DecimalPipe } from '@angular/common';
import { UtilService } from '../Services/util.service';
import Swal from 'sweetalert2';

function MinAmountValidator(min): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let value = parseFloat(String(control.value).replace(/,/g, ''));
    if (value !== undefined && (isNaN(value) || value < parseFloat(min))) {
      return { 'minAmount': true };
    }
    return null;
  };
}

function MaxAmountValidator(max): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let value = parseFloat(String(control.value).replace(/,/g, ''));
    if (value !== undefined && (isNaN(value) || value > parseFloat(max))) {
      return { 'maxAmount': true };
    }
    return null;
  };
}

@Component({
  selector: '[app-index]',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
  // styleUrls: ['./index.component.css', './index.component.' + lang + '.css']
})
export class IndexComponent implements OnInit {

  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  public title: "Ping Money | Send money to Gambia - Online money transfer";
  public loggedIn: boolean;
  public disabled: boolean = true;
  public deliveryMethodPlaceholder: boolean = true;
  public HitTimer: any = null;
  public AllData = null;
  public data:any = { 
                      amount: 100.00, 
                      formatted_sending_amount: 100.00, 
                      sending_amount: 100.00, 
                      receiving_amount: 6200.00, 
                      formatted_receiving_amount: 6200.00, 
                      first_call: false, 
                      direction: 'SEND'};
  public response = null;
  public source_country = null;
  public partnerLogos = [];
  public destination_country = null;
  public delivery_methods = null;
  public selectedSourceCountry = null;
  public selectedDestinationCountry = null;
  public selectedDeliveryMethod = null;

  submitted = false;
  loadText = false;

  sendMoney: UntypedFormGroup;

  constructor(
      private Auth: AuthServices,
      private spinner: NgxSpinnerService,
      private ApiCall: ApiCallService,
      private formBuilder: UntypedFormBuilder,
      private router: Router,
      private Title: Title,
      private _renderer2: Renderer2,
      private decimalPipe: DecimalPipe,
      private utils: UtilService,
      @Inject(DOCUMENT) private _document: Document
    ) { 
      
    }

  ngOnInit() {

    this.Title.setTitle('Ping Money | Send money to Gambia - Online money transfer');
    this.Auth.authStatus.subscribe(value => this.loggedIn = value);
    this.removeAllSessions()

    this.spinner.show();
     this.callApi(true);

     this.sendMoney = this.formBuilder.group({
      'sending_amount': [100.00, [
        Validators.required,
        MinAmountValidator(10),
        MaxAmountValidator(4000)
      ]],
      'receiving_amount': [null,[
        Validators.required,
        MinAmountValidator(10),
        MaxAmountValidator(4000)
      ]],
      'delivery_method_id': [null]
    });    
      
      let script = this._renderer2.createElement('script');
            script.text = `
            new carouselInlineWidget('carousel-inline-widget-810',{
              store: 'www-pingmoney-co',
              sku: '',
              lang: 'en',
              carousel_type: 'default',
              styles_carousel: 'CarouselWidget--sideHeader--withcards',
        
              options:{
                general:{
                  review_type: 'company, product',
                  min_reviews: '1',
                  max_reviews: '20',
                  address_format: 'CITY, COUNTRY',
                  enable_auto_scroll: 10000,
                },
                header:{
                  enable_overall_stars: true,
                },
                reviews: {
                  enable_customer_name: true,
                  enable_customer_location: true,
                  enable_verified_badge: true,
                  enable_recommends_badge: true,
                  enable_photos: true,
                  enable_videos: false,
                  enable_review_date: false,
                  disable_same_customer: true,
                  min_review_percent: 4,
                  third_party_source: true,
                  hide_empty_reviews: true,
                  enable_product_name: false,
                  tags: "",
                  branch: "",
                  enable_branch_name: false,
                },
                popups: {
                  /*Make review items clickable (When they are clicked, a popup appears with more information about a customer and review)*/
                  enable_review_popups:  true,
                  enable_helpful_buttons: true,
                  enable_helpful_count: true,
                  enable_share_buttons: false,
                },
              },
              styles:{
                '--base-font-size': '16px',
                '--base-maxwidth':'100%',
        
                /*Logo styles:*/
                '--reviewsio-logo-style':'var(--logo-inverted)',
        
                /*Star styles:*/
                '--common-star-color':'#509c97',
                '--common-star-disabled-color':' rgba(0,0,0,0.25)',
                '--medium-star-size':' 22px',
                '--small-star-size':'19px', /*Modal*/
                '--x-small-star-size':'16px',
                '--x-small-star-display':'inline-flex',
        
                /*Header styles:*/
                '--header-order':'1',
                '--header-width':'280px',
                '--header-bg-start-color':'#0d3848',
                '--header-bg-end-color':'#0d3848',
                '--header-gradient-direction':'135deg',
                '--header-padding':'1.5em',
                '--header-border-width':'0px',
                '--header-border-color':'rgba(0,0,0,0.1)',
                '--header-border-radius':'0px',
                '--header-shadow-size':'10px',
                '--header-shadow-color':'rgba(0, 0, 0, 0.05)',
        
                /*Header content styles:*/
                '--header-star-color':'#ffffff',
                '--header-disabled-star-color':'inherit',
                '--header-heading-text-color':'#ffffff',
                '--header-heading-font-size':'inherit',
                '--header-heading-font-weight':'inherit',
                '--header-heading-line-height':'inherit',
                '--header-heading-text-transform':'inherit',
                '--header-subheading-text-color':'#ffffff',
                '--header-subheading-font-size':'inherit',
                '--header-subheading-font-weight':'300',
                '--header-subheading-line-height':'inherit',
                '--header-subheading-text-transform':'inherit',
        
                /*Review item styles:*/
                '--item-maximum-columns':'5',/*Must be 3 or larger*/
                '--item-background-start-color':'#ededed',
                '--item-background-end-color':'#ededed',
                '--item-gradient-direction':'135deg',
                '--item-padding':'1.5em',
                '--item-border-width':'0px',
                '--item-border-color':'rgba(0,0,0,0.1)',
                '--item-border-radius':'0px',
                '--item-shadow-size':'0px',
                '--item-shadow-color':'rgba(0,0,0,0.05)',
        
                /*Heading styles:*/
                '--heading-text-color':' #0E1311',
                '--heading-text-font-weight':' 600',
                '--heading-text-font-family':' inherit',
                '--heading-text-line-height':' 1.4',
                '--heading-text-letter-spacing':'0',
                '--heading-text-transform':'none',
        
                /*Body text styles:*/
                '--body-text-color':' #0E1311',
                '--body-text-font-weight':'400',
                '--body-text-font-family':' inherit',
                '--body-text-line-height':' 1.4',
                '--body-text-letter-spacing':'0',
                '--body-text-transform':'none',
        
                /*Scroll button styles:*/
                '--scroll-button-icon-color':'#0E1311',
                '--scroll-button-icon-size':'24px',
                '--scroll-button-bg-color':'transparent',
        
                '--scroll-button-border-width':'0px',
                '--scroll-button-border-color':'rgba(0,0,0,0.1)',
        
                '--scroll-button-border-radius':'60px',
                '--scroll-button-shadow-size':'0px',
                '--scroll-button-shadow-color':'rgba(0,0,0,0.1)',
                '--scroll-button-horizontal-position':'3px',
                '--scroll-button-vertical-position':'0px',
        
                /*Badge styles:*/
                '--badge-icon-color':'#5a9f9a',
                '--badge-icon-font-size':'18px',
                '--badge-text-color':'#0E1311',
                '--badge-text-font-size':'inherit',
                '--badge-text-letter-spacing':'inherit',
                '--badge-text-transform':'inherit',
        
                /*Author styles:*/
                '--author-font-size':'18px',
                '--author-font-weight':'inherit',
                '--author-text-transform':'inherit',
        
                /*Product photo or review photo styles:*/
                '--photo-video-thumbnail-size':'60px',
                '--photo-video-thumbnail-border-radius':'0px',
        
                /*Popup styles:*/
                '--popup-backdrop-color':'rgba(0,0,0,0.75)',
                '--popup-color':'#ffffff',
                '--popup-star-color':'inherit',
                '--popup-disabled-star-color':'inherit',
                '--popup-heading-text-color':'inherit',
                '--popup-body-text-color':'inherit',
                '--popup-badge-icon-color':'inherit',
                '--popup-badge-icon-font-size':'19px',
                '--popup-badge-text-color':'inherit',
                '--popup-badge-text-font-size':'14px',
                '--popup-border-width':'0px',
                '--popup-border-color':'rgba(0,0,0,0.1)',
                '--popup-border-radius':'0px',
                '--popup-shadow-size':'0px',
                '--popup-shadow-color':'rgba(0,0,0,0.1)',
                '--popup-icon-color':'#0E1311',
        
                /*Tooltip styles:*/
                '--tooltip-bg-color':'#0E1311',
                '--tooltip-text-color':'#ffffff',
              },
            });
        `;

    this._renderer2.appendChild(this._document.body, script);

  }

  get f() { return this.sendMoney.controls; }

  onChange(direction)
  {
    this.disabled = true;   
    
    this.data.direction = direction;
    if(direction == 'SEND')
    {
      this.data.amount = this.data.sending_amount
    }
    else
    {
      this.data.amount = this.data.receiving_amount
    }
    this.callApi();
  }

  callApi(first_call = false)
  {    
    if(this.HitTimer){ //if there is already a timeout in process cancel it
      window.clearTimeout(this.HitTimer);
    }
    this.data.first_call = first_call ? true : false
    
    this.data.amount = parseFloat(String(this.data.amount).replace(/,/g, ''))
    
    this.HitTimer = window.setTimeout(() => {
      this.HitTimer = null;        
      this.ApiCall.get_transfer_rates(this.data).subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      );
    },2000);

  }

  handleResponse(response)
  {
    this.spinner.hide();
    this.response = response;
    this.loadText = true;
    
    this.data.sending_amount = this.decimalPipe.transform(response.rates.sending_amount, '1.0-2');
    this.data.receiving_amount = this.decimalPipe.transform(response.rates.receiving_amount, '1.0-2');

    this.source_country = response.source_country;    
    this.partnerLogos = response.partnerLogo;    
    this.destination_country = response.destination_country;   
    this.delivery_methods = response.delivery_methods; 
    this.selectedSourceCountry = this.getSelectedSourceCountry(this.source_country)  
    this.selectedDestinationCountry = this.getSelectedDestinationCountry(this.destination_country) 
    this.selectedDeliveryMethod = this.getSelectedDeliveryMethod(this.delivery_methods)
    
    this.disabled = false;
    this.deliveryMethodPlaceholder = false;
    
    let receiving_amount_validation:any = [];

    if(response.rates.apply_destination_limits == true)
    {      
      receiving_amount_validation= [this.data.receiving_amount, [
        Validators.required,
        MinAmountValidator(response.rates.des_lowerlimit),
        MaxAmountValidator(response.rates.des_higherlimit)
      ]]
    }
    else
    {
      receiving_amount_validation= [this.data.receiving_amount] 
    }

    this.sendMoney = this.formBuilder.group({
      'sending_amount': [this.data.sending_amount, [
        Validators.required,
        MinAmountValidator(response.rates.src_lowerlimit),
        MaxAmountValidator(response.rates.src_higherlimit)
      ]],
      'receiving_amount': receiving_amount_validation,
      'delivery_method_id': [null]
    });
  }

  getSelectedSourceCountry(source_countries)
  {
    for (let i = 0; i < source_countries.length; i++) {  
      if (source_countries[i].selected == 1) {
        this.data.source_country_id = source_countries[i].id
          return source_countries[i] 
      }    
    }
  }

  getSelectedDestinationCountry(destination_countries)
  {
    for (let i = 0; i < destination_countries.length; i++) {  
      if (destination_countries[i].selected == 1) {
        this.data.destination_country_id = destination_countries[i].id
          return destination_countries[i] 
      }    
    }
  }

  getSelectedDeliveryMethod(delivery_methods)
  {
    for (let i = 0; i < delivery_methods.length; i++) {  
      if (delivery_methods[i].selected == 1) {
        this.data.delivery_method_id = delivery_methods[i].id
          return delivery_methods[i] 
      }
    }
  }
  
  SelectSourceCountry(source_country)
  {
    if(source_country.id == this.selectedSourceCountry.id)
    {
       return false;
    }
    else{ 
      this.disabled = true;
      this.deliveryMethodPlaceholder = true;
      this.selectedSourceCountry = source_country;
      this.data.source_country_id = source_country.id;  
      this.data.delivery_method_id = null;  
      this.data.destination_country_id = null;         
      this.data.amount = this.data.sending_amount;   
      this.data.direction = "SEND";    
      this.callApi();
    }
  }
  
  SelectDestinationCountry(destination_country)
  {
    if(destination_country.id == this.selectedDestinationCountry.id)
    {
       return false;
    }
    else{ 
      this.disabled = true;
      this.deliveryMethodPlaceholder = true;
      this.selectedDestinationCountry = destination_country;
      this.data.destination_country_id = destination_country.id;    
      this.data.source_country_id = this.selectedSourceCountry.id;
      this.data.delivery_method_id = null;
      this.data.amount = this.data.sending_amount;    
      this.data.direction = "SEND";
      this.callApi();
    }
  }
  
  SelectDeliveryMethod(delivery_method)
  {
    if(delivery_method.id == this.selectedDeliveryMethod.id)
    {
       return false;
    }
    else{ 
      this.disabled = true;
      this.deliveryMethodPlaceholder = false;
      this.selectedDeliveryMethod = delivery_method;      
      this.data.destination_country_id = this.selectedDestinationCountry.id;    
      this.data.source_country_id = this.selectedSourceCountry.id;       
      this.data.delivery_method_id = this.selectedDeliveryMethod.id;      
      this.data.amount = this.data.sending_amount;    
      this.data.direction = "SEND";
      this.callApi();    
    }

  }

  handleError(error)
  {    
    this.spinner.hide();
  }  

  onSubmit()
  {   
    this.submitted = true;

    if (this.sendMoney.invalid) {
      return;
    }
    
    this.data.sending_amount = parseFloat(String(this.data.sending_amount).replace(/,/g, ''))
    this.data.receiving_amount = parseFloat(String(this.data.receiving_amount).replace(/,/g, ''))

    this.data.delivery_method_text = this.selectedDeliveryMethod.text
    this.data.delivery_method_name = this.selectedDeliveryMethod.name
    this.data.source_country = this.selectedSourceCountry;
    this.data.destination_country = this.selectedDestinationCountry;
    this.data.duration = this.response.rates.duration;
    this.data.monthly_limit = this.response.rates.monthly_limit;
    this.data.yearly_limit = this.response.rates.yearly_limit;
    this.data.offerrate = this.response.rates.offerrate;
    this.data.total_pay = this.response.rates.total_pay;   
    this.data.fees = this.response.rates.fees;    
    this.data.actual_fees = this.response.rates.actual_fees;    
    this.data.phone_regex = this.response.rates.phone_regex;    

    sessionStorage.setItem('paymentSession','true');
    sessionStorage.setItem("sendMoneyDetails", JSON.stringify(this.data));
    
    if(!this.loggedIn)
    {
      this.router.navigateByUrl('/auth/login');
    }
    else{
      this.spinner.show();
      this.checkLimits();
    }
  }
  
  checkLimits() {
    var limit = {
      amount: this.data.sending_amount,
      source_currency_code: this.selectedSourceCountry.currency_code,
    }

    this.ApiCall.checkLimits(limit).subscribe(
      data => {
        this.spinner.hide();
        this.router.navigateByUrl('/transfer/recipient');
      },
      error => {
        this.showLimitExceededAlert(error.error.message)
        this.spinner.hide();
      }

    );
  }

  showLimitExceededAlert(message) {
    Swal.fire({
      title: "Sending limit exceeded",
      html: this.swalHtml(message),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Increase Limits',
      cancelButtonText: 'Change Amount'
    }).then((result) => {
      if (result.value) {
        this.router.navigateByUrl('/limits');
      }
    });
  }
  
  swalHtml(message) {
    return `
    ${message}
    `;
  }
    
  removeAllSessions(){    
    sessionStorage.clear();   
    localStorage.removeItem('login_email');
    localStorage.removeItem('login_password');
    localStorage.removeItem('login_type');
    localStorage.removeItem('network');
    localStorage.removeItem('provider_id');
    localStorage.removeItem('saveToDb');
  }

}
