import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  public appUrl = environment.appUrl;
  constructor(
    private spinner: NgxSpinnerService,
    private Title: Title) { }

  ngOnInit() {
    
  }

}
