import { DecimalPipe } from '@angular/common';
import { Directive, HostListener, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: 'input[currencyFormatter]'
})

export class CurrencyFormatter implements OnInit {

    @Input('numericType') numericType: string; // number | decimal

    private el: HTMLInputElement;

    private regex = {
        number: new RegExp(/^\d+$/),
        decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
    };
    
    // (?:\+|\-|\$)?\d{1,}(?:\,?\d{3})*(?:\.\d+)?%?

    private specialKeys = {
        number: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' ],
        decimal: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' ],
    };

    constructor(private elementRef: ElementRef,
        private decimalPipe: DecimalPipe) {
            
        this.el = this.elementRef.nativeElement;
    }

    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {

        if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        let current: string = this.elementRef.nativeElement.value;
        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex[this.numericType])) {
            event.preventDefault();
        }
    }
        
    ngOnInit() {
        this.el.value = this.decimalPipe.transform(String (this.el.value).replace(/,/g, ''));    
    }

    @HostListener("focus", ["$event.target.value"])
    onFocus(value) {
        this.el.value = String (value).replace(/,/g, '');
    }

    @HostListener("focusout", ["$event.target.value"])
    onFocusOut(value) {
        this.el.value = this.formatToCurrency(String (value).replace(/,/g, ''));
    }

    formatToCurrency(val) {
        return this.decimalPipe.transform(String (val).replace(/,/g, ''), '1.0-2');
    }
}