<!-- myprofile-section starts-->
<section class="section myprofile-section ">
  <div class="container">
    <div class="row">
      <div class="col-md-9">
        <router-outlet></router-outlet>
      </div>

      <div class="col-md-3">
        <mat-card class="profile-card" *ngIf="!isMobile">
          <div class="profile-card-header clearfix">
            <div class="image">
              <img [src]="imgSrc" alt="">
              <label *ngIf="canUploadImage" (click)="openModal(changeAvatarModal)" class="camera-image">
                <img src="{{ assetUrl }}images/icons/camera.png" alt="">
              </label>
            </div>
            <div class="box">
              <h4>{{ userDetails.first_name }}</h4>
              <span #tooltip="matTooltip" [matTooltip]="userDetails?.phone">{{ userDetails?.phone ? userDetails?.phone : '' }}</span>
            </div>
          </div>
          
          <app-profile-sidebar-menu></app-profile-sidebar-menu>
        </mat-card>
      </div>
    </div>
  </div>
</section>
<!-- Modal -->
<ng-template #changeAvatarModal let-modal>
  <div class="modal-header">
    <button type="button" class="close" data-dismiss="modal" (click)="modal.dismiss()">
      <img src="{{ assetUrl }}images/icons/cancel.png" alt="">
    </button>
  </div>
  <div class="modal-body p-0  text-center">

    <h3>Please select avatar to upload</h3>
    <div class="alert alert-danger" [hidden]='!imageError'> {{imageError}} </div>

    <input class="display_none" type="file" #imageSelect name="filename" (change)="fileChangeEvent($event)" />
    <button class="select_file_btn" (click)="imageSelect.click()">Select file</button>
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
      [resizeToWidth]="128" [cropper]="coordinates" format="png" (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()" [cropper]=" coordinates"></image-cropper>

    <!-- <img [src]="croppedImage" /> -->
    <br>
    <div class="authentication-detail">
      <button mat-flat-button class="btn-style" *ngIf="croppedImage" (click)="UploadProfilePic()">Upload Profile Photo</button>
    </div>
  </div>
</ng-template>
<!-- myprofile-section ends-->