<div class="contact-us">
  <div class="bg-section">
    <div class="container">
      <div class="bg-text">
        <h1>Have a question for us?</h1>
        <p>We are here to help you!
          <br> Here are a few ways to get in touch with us.
        </p>
      </div>
    </div>
  </div>

  <section class="contact-form section">
    <div class="container">

      <div class="row">
        <div class="col-md-8">
          <h3 class="title">Write to us</h3>
          <div class="spacer_10"></div>
          <p>If you can’t find the answer in our FAQ, we're here to help! <br><br>
            Just fill in the form below and we’ll be in touch as soon as we can. If you have a transaction number <br>or
            customer number, be sure to include it but <strong>please don’t send us your card details</strong>.
          </p>

          <div class="alert alert-danger" [hidden]='!error'>
            <ul *ngIf="error != null">
              <li *ngFor="let err of error; let i = index">{{ err }}</li>
            </ul>
          </div>
          <div class="alert alert-success" [hidden]='!success'> {{success}} </div>
          <form [formGroup]="ContactForm" (ngSubmit)="onSubmit()" #ContactFormData="ngForm" novalidate>
            <div class="row">
              <div class="col-md-5">
                <mat-form-field>
                  <input matInput type="text" formControlName="name" placeholder="Name" [(ngModel)]="formData.name">
                  <mat-error *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Name is required</div>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-5 col-md-offset-2">
                <mat-form-field>
                  <input matInput type="text" formControlName="email" placeholder="Email" [(ngModel)]="formData.email">
                  <mat-error *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                  </mat-error>
                </mat-form-field>
              </div>


              <div class="col-md-5">
                <mat-form-field>
                  <mat-select placeholder="Country" formControlName="country" [(ngModel)]="selected_country">
                    <mat-option *ngFor="let country of countries; let i = index" [value]="country">{{ country.name
                      }}</mat-option>
                    <mat-error *ngIf="submitted && f.country.errors" class="invalid-feedback">
                      <div *ngIf="f.country.errors.required">Please select country</div>
                    </mat-error>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-5  col-md-offset-2">
                <mat-form-field>
                  <div matPrefix class="country-code-label">+{{ selected_country?.calling_code }}
                    <span class="caret"></span>&nbsp;
                  </div>
                  <input type="number" formControlName="phone" matInput placeholder="Mobile"
                    [(ngModel)]="formData.phone">
                  <mat-error *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                    <div *ngIf="f.phone.errors.required">Phone no. is required</div>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field>
                  <textarea matInput placeholder="Description" formControlName="message" rows="8" cols="50"></textarea>
                  <mat-error *ngIf="submitted && f.message.errors" class="invalid-feedback">
                    <div *ngIf="f.message.errors.required">Description is required</div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <button mat-flat-button type="submit" class="btn-style continue-btn">Send Message</button>
          </form>
        </div>
        <div class="col-md-4">
          <h3 class="title">Call us</h3>
          <div class="contact_info_part">
            <p class="call_section"><i class="fas fa-phone-alt"></i><a href="tel:03330509149">0333 050 9149</a></p>
            <p class="call_section"><img src="{{ assetUrl }}images/icons/whatsapp.png" alt=""> <a target="_blank"
                href="https://api.whatsapp.com/send?phone=447448800354">+44 7448 800354</a></p>
            <br>
            <br>
            <h3 class="title">Like & Follow Us</h3>
            <br>
            <ul class="social-links">
              <li>
                <a target="_blank" href="https://www.facebook.com/pingmoneyapp">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://twitter.com/pingmoneyapp">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section address">
    <div class="container contact-tabs">

      <div class="row mt-3 mb-2">
        <div class="col-md-4">
          <div class="address-text text-center">
            <img class="mb-3" src="{{ assetUrl }}images/flags/GBP.png" alt="">
            <h2>United Kingdom</h2>
            <p>Future Business Centre Cambridge, <br>
              King’s Hedges Road, <br>
              Cambridge, <br>
              CB4 2HY
            </p>
            <p><a href="mailto:hello@pingmoney.co">hello@pingmoney.co</a></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="address-text text-center">
            <img class="mb-3" src="{{ assetUrl }}images/flags/usa.png" alt="">
            <h2>United States</h2>
            <p>554, 1007 N Orange St. 4th Floor,
                <br> Wilmington,
                <br>Delaware, 19801.
            </p>
            <p><a href="tel:17862235388">+1-786-223-5388</a></p>
            <p><a href="mailto:hello@pingmoney.co">hello@pingmoney.co</a></p>
            <p><strong>Opening hours</strong>: MON-FRI 9:00 to 17:00 ET </p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="address-text text-center last-margin">
            <img class="mb-3" src="{{ assetUrl }}images/flags/GMD.png" alt="">
            <h2>Gambia</h2>
            <p>Standard Chartered House, <br>
              Traffic Light, <br>
              Kairaba Avenue, Fajara
            </p>
            <br>
            <p><a href="mailto:hello@pingmoney.co">hello@pingmoney.co</a></p>
          </div>
        </div>
      </div>

    </div>
  </section>

</div>