import { Component, OnInit } from '@angular/core';
import { AuthServices } from '../Services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiCallService } from '../Services/api-call.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rate-comparision',
  templateUrl: './rate-comparision.component.html',
  styleUrls: ['./rate-comparision.component.css']
})
export class RateComparisionComponent implements OnInit {

  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  public price_list= null;
  constructor(
    private Auth: AuthServices,
      private spinner: NgxSpinnerService,
      private ApiCall: ApiCallService,
      private formBuilder: UntypedFormBuilder,
      private router: Router,
      private Title: Title
    ) { }

  ngOnInit() {
    
    this.Title.setTitle('Price Chart');
    this.ApiCall.get_price_matrix().subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );   
  }

  handleResponse(data)
  {
     this.spinner.hide();  
    this.price_list = data;
    
  }

  handleError(error)
  {    
    this.spinner.hide();
  }

  scrollTOElement = (element, offsetParam?, speedParam?) => {
    const toElement = $(element);
    const focusElement = $(element);
    const offset = offsetParam * 1 || 200;
    const speed = speedParam * 1 || 100;
    $('html, body').animate({
      scrollTop: toElement.offset().top 
    }, speed);
    if (focusElement) {
      $(focusElement).focus();
    }
  }

}
