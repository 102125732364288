
<div>
    <ng-sidebar-container  class="mobile_header">
        <div id="body-wrapper" class="wrapper" ng-sidebar-content>
            <router-outlet></router-outlet>

            <ngx-spinner>
                <div class="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </ngx-spinner>


            <!-- backtotop btn -->
            <div class="backtotop-btn" id="toTop">
                <i class="fas fa-long-arrow-alt-up"></i>
            </div>
            <div class="cookie-banner-wrapper"  *ngIf="cookieValue == 'false'">
                <p class="cookie-text"> We use cookies to ensure you get the best experience on our website. <a routerLink="/legal/cookie-policy">Learn more</a></p>
                <button class="cookie-btn" (click)="setCookieBannerTrue('true')">Got it!</button>
            </div>

            <!-- backtotop btn -->
        </div>
    </ng-sidebar-container>
</div>