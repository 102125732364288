import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class ApiCallService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  login(data) {
    return this.http.post(`${this.baseUrl}/login`, data);
  }

  validateLogin(data) {
    return this.http.post(`${this.baseUrl}/validate/login`, data);
  }

  login_with_pin(data) {
    return this.http.post(`${this.baseUrl}/pin/login`, data);
  }

  verifyPhone(data) {
    return this.http.post(`${this.baseUrl}/verify/phone`, data);
  }

  resendVerifyPhone(data) {
    return this.http.post(`${this.baseUrl}/resend/verify/phone`, data);
  }

  otp_login(data) {
    return this.http.post(`${this.baseUrl}/two-factor-login`, data);
  }

  verifyPasswordOtp(data) {
    return this.http.post(`${this.baseUrl}/password/verify/otp`, data);
  }

  social_login(data) {
    return this.http.post(`${this.baseUrl}/login/social`, data);
  }

  invalidateApi(data) {
    return this.http.post(`${this.baseUrl}/logout`, data);
  }

  validateSignup(data) {
    return this.http.post(`${this.baseUrl}/validate/register`, data);
  }

  signup(data) {
    return this.http.post(`${this.baseUrl}/register`, data);
  }

  getUserByToken(token) {
    return this.http.get(`${this.baseUrl}/user/by/token/${token}`);
  }

  completeSignup(data) {
    return this.http.post(`${this.baseUrl}/complete/signup`, data);
  }

  updatePhoneNo(data) {
    return this.http.post(`${this.baseUrl}/update/phoneno`, data);
  }

  verify_email(data) {
    return this.http.post(`${this.baseUrl}/register/verify-email`, data);
  }

  forgot_password(data) {
    return this.http.post(`${this.baseUrl}/password/remind`, data);
  }

  reset_password(data) {
    return this.http.post(`${this.baseUrl}/password/reset`, data);
  }

  change_password(data) {
    return this.http.post(`${this.baseUrl}/password/change`, data);
  }

  get_profile() {
    return this.http.get(`${this.baseUrl}/me?include=Customer`);
  }

  get_settings() {
    return this.http.get(`${this.baseUrl}/me/settings`);
  }

  save_user_settings(data) {
    return this.http.post(`${this.baseUrl}/me/saveUserSettings`, data);
  }

  get_general_kyc_information() {
    return this.http.get(`${this.baseUrl}/get/general/kyc/information`);
  }

  get_kyc_details() {
    return this.http.get(`${this.baseUrl}/get/kyc`);
  }

  getCountries() {
    return this.http.get(`${this.baseUrl}/countries`);
  }

  getRecipientCountries() {
    return this.http.get(`${this.baseUrl}/recipients/countries`);
  }

  getKycCountries() {
    return this.http.get(`${this.baseUrl}/kyc/countries`);
  }

  getCountry(id) {
    return this.http.get(`${this.baseUrl}/get/${id}/country`);
  }

  getStates() {
    return this.http.get(`${this.baseUrl}/states`);
  }

  get_banks(data) {
    return this.http.post(`${this.baseUrl}/banks`, data);
  }

  get_provider(data) {
    return this.http.post(`${this.baseUrl}/providers`, data);
  }

  get_relations() {
    return this.http.get(`${this.baseUrl}/relation`);
  }

  update_profile(data) {
    return this.http.patch(`${this.baseUrl}/me/details`, data);
  }

  update_profile_photo(data) {
    return this.http.post(`${this.baseUrl}/me/avatar`, data);
  }

  get_documents() {
    return this.http.get(`${this.baseUrl}/documents`);
  }

  getSilaDocTypes() {
    return this.http.get(`${this.baseUrl}/kyc/doc/types`);
  }

  checkSMSConfirmation() {
    return this.http.get(`${this.baseUrl}/sms/confirmation`);
  }

  requestSilaKyc() {
    return this.http.get(`${this.baseUrl}/kyc/request`);
  }

  checkSilaKyc() {
    return this.http.get(`${this.baseUrl}/kyc/check`);
  }

  checkMachnetKyc() {
    return this.http.get(`${this.baseUrl}/kyc/check/machnet`);
  }

  checkMachnetTransactionStatus(transactionId) {
    return this.http.get(
      `${this.baseUrl}/check/machnet/transfer/status/${transactionId}`
    );
  }

  addDeviceSila(data) {
    return this.http.post(`${this.baseUrl}/add/device`, data);
  }

  updateBankAccountName(uuid, data) {
    return this.http.post(
      `${this.baseUrl}/update/sila/account/${uuid}/name`,
      data
    );
  }

  deleteBankAccountName(uuid) {
    return this.http.delete(`${this.baseUrl}/delete/sila/${uuid}/account`);
  }

  deleteMachnetAccountName(uuid) {
    return this.http.delete(`${this.baseUrl}/delete/machnet/${uuid}/account`);
  }

  get_cards() {
    return this.http.get(`${this.baseUrl}/get/cards`);
  }

  kyc_submit_general_details(data) {
    return this.http.post(`${this.baseUrl}/kyc/general/details`, data);
  }

  kyc_submit_upload_documents(data) {
    return this.http.post(`${this.baseUrl}/kyc/upload/documents`, data);
  }

  kycUploadDocumentsGm(data) {
    return this.http.post(`${this.baseUrl}/gm/kyc/upload/documents`, data);
  }

  kyc_submit_veriff(data) {
    return this.http.post(`${this.baseUrl}/kyc/upload/veriff`, data);
  }

  getRecipient(countryId: string, page: number, search: string) {
    return this.http.get(
      `${this.baseUrl}/recipients?page=${page}&country_id=${countryId}&search=${search}`
    );
  }

  createRecipient(data) {
    return this.http.post(`${this.baseUrl}/recipients/store`, data);
  }

  check_recipients_details(id, data) {
    return this.http.post(
      `${this.baseUrl}/check/recipients/details/` + id,
      data
    );
  }

  editRecipient(recipientId) {
    return this.http.get(`${this.baseUrl}/recipients/${recipientId}/edit`);
  }

  updateRecipient(recipientId, data) {
    return this.http.post(
      `${this.baseUrl}/recipients/${recipientId}/update`,
      data
    );
  }

  updateRecipientBank(recipientId, data) {
    return this.http.post(
      `${this.baseUrl}/recipients/bank/${recipientId}/update`,
      data
    );
  }

  updateRecipientWallet(recipientId, data) {
    return this.http.post(
      `${this.baseUrl}/recipients/wallet/${recipientId}/update`,
      data
    );
  }

  deleteRecipient(recipientId) {
    return this.http.delete(`${this.baseUrl}/recipients/${recipientId}`);
  }

  recipientSetup(data) {
    return this.http.post(`${this.baseUrl}/recipients/setup`, data);
  }

  getDeliveryMethods(countryId) {
    return this.http.get(`${this.baseUrl}/delivery/methods/${countryId}`);
  }

  get_transfer_rates(data) {
    return this.http.post(`${this.baseUrl}/rate/config`, data);
  }

  getUpdatedRates(data) {
    return this.http.post(`${this.baseUrl}/update/rates/config`, data);
  }

  get_price_matrix() {
    return this.http.get(`${this.baseUrl}/GetPriceList`);
  }

  sendAgain(transferUUID) {
    return this.http.get(`${this.baseUrl}/send/${transferUUID}/again`);
  }

  checkLimits(data) {
    return this.http.post(`${this.baseUrl}/check/limits`, data);
  }

  getLimitsById(id) {
    return this.http.get(`${this.baseUrl}/get/tier/${id}`);
  }

  getLimits() {
    return this.http.get(`${this.baseUrl}/get/limits`);
  }

  submitLimitIncrease(data) {
    return this.http.post(`${this.baseUrl}/request/limit/increase`, data);
  }

  getLimitSubmissionStatus() {
    return this.http.get(`${this.baseUrl}/limit/submission/status`);
  }

  getSourceOfFunds() {
    return this.http.get(`${this.baseUrl}/SourceOfFunds`);
  }

  getPurposeOfTransfer() {
    return this.http.get(`${this.baseUrl}/PurposeOfTransfer`);
  }

  getReferralPoints() {
    return this.http.get(`${this.baseUrl}/get/referral/points`);
  }

  apply_coupon(data) {
    return this.http.post(`${this.baseUrl}/ApplyCouponCode`, data);
  }

  apply_referral(data) {
    return this.http.post(`${this.baseUrl}/apply/referral/point`, data);
  }

  apply_bill_referral(data) {
    return this.http.post(`${this.baseUrl}/apply/referral/point/bill`, data);
  }

  get_transfers(data) {
    return this.http.get(`${this.baseUrl}/TransferList?${data}`);
  }

  getTransferStatusList() {
    return this.http.get(`${this.baseUrl}/transfer/statues`);
  }

  generate_payments_token() {
    return this.http.get(`${this.baseUrl}/generate/payment/token`);
  }

  create_single_payment(data) {
    return this.http.post(`${this.baseUrl}/create/single/payment`, data);
  }

  createTransferWithTrueLayer(data) {
    return this.http.post(`${this.baseUrl}/transfer/pay/with/bank`, data);
  }

  createEasyPayTransfer(data) {
    return this.http.post(`${this.baseUrl}/transfer/pay/with/easypay`, data);
  }

  create_transfer(data) {
    return this.http.post(`${this.baseUrl}/TransferPayWithBank`, data);
  }

  create_wirecard_transfer(data) {
    return this.http.post(`${this.baseUrl}/TransferPayWithWirecard`, data);
  }

  createSecureTradingTransfer(data) {
    return this.http.post(
      `${this.baseUrl}/transfer/pay/with/secure/trading`,
      data
    );
  }

  createAcquiredTransfer(data) {
    return this.http.post(`${this.baseUrl}/transfer/pay/with/card`, data);
  }

  createAcquiredCardTransfer(data) {
    return this.http.post(
      `${this.baseUrl}/transfer/pay/with/acquired/card/api`,
      data
    );
  }

  getAppleSession() {
    return this.http.get(`${this.baseUrl}/apple/pay/merchant/session`);
  }

  acquiredScaChallengeCheck(data) {
    return this.http.post(`${this.baseUrl}/acquired/auth/capture`, data);
  }

  createTransferViaWallet(data) {
    return this.http.post(`${this.baseUrl}/transfer/pay/with/wallet`, data);
  }

  createTransferViaRewardWallet(data) {
    return this.http.post(
      `${this.baseUrl}/transfer/pay/with/reward-wallet`,
      data
    );
  }

  create_transfer_paypal(data) {
    return this.http.post(`${this.baseUrl}/TransferPayWithPaypal`, data);
  }

  getBankDetails(data) {
    return this.http.get(
      `${this.baseUrl}/bank/details?currency_code=` + data.currency_code
    );
  }

  createPaymentOptions(data) {
    return this.http.post(`${this.baseUrl}/payment/methods`, data);
  }

  getTransferDetails(uuid) {
    return this.http.get(`${this.baseUrl}/transfer/details/` + uuid);
  }

  getRetryPaymentDetails(paymentId) {
    return this.http.get(`${this.baseUrl}/payment/retry/` + paymentId);
  }

  getCancelReasons() {
    return this.http.get(`${this.baseUrl}/cancel/reasons`);
  }

  getAccountDeletionReasons() {
    return this.http.get(`${this.baseUrl}/account/deletion/reasons`);
  }

  requestAccountDeletion() {
    return this.http.get(`${this.baseUrl}/request/account/delete`);
  }

  updateRecipientForTransfer(id, post) {
    return this.http.post(`${this.baseUrl}/update/${id}/recipient`, post);
  }

  export_pdf(data) {
    return this.http.get(`${this.baseUrl}/export_pdf/` + data);
  }

  cancelTrasnfer(id, data) {
    return this.http.post(`${this.baseUrl}/cancel/${id}/transfer`, data);
  }

  get_support_list(data) {
    return this.http.get(`${this.baseUrl}/SupportList?page=` + data);
  }

  create_support(data) {
    return this.http.post(`${this.baseUrl}/CreateSupport`, data);
  }

  mark_complete_ticket(data) {
    return this.http.get(`${this.baseUrl}/MarkAsCompletedTicket/` + data);
  }

  re_open_ticket(data) {
    return this.http.get(`${this.baseUrl}/reOpenTicket/` + data);
  }

  getTicketDetail(data) {
    return this.http.get(`${this.baseUrl}/getTicketDetails/` + data);
  }

  create_reply(data) {
    return this.http.post(`${this.baseUrl}/storeReply`, data);
  }

  send_otp(data) {
    return this.http.post(`${this.baseUrl}/me/SendOTP`, data);
  }
  enable_two_factor(data) {
    return this.http.post(`${this.baseUrl}/me/enableTwoFactor`, data);
  }

  disable_two_factor(data) {
    return this.http.post(`${this.baseUrl}/me/disableTwoFactor`, data);
  }

  //CMS PAGES

  faq() {
    return this.http.get(`${this.baseUrl}/faq`);
  }

  inquiry(data) {
    return this.http.post(`${this.baseUrl}/inquiry`, data);
  }

  wirecard_payment_request(data) {
    return this.http.post(`${this.baseUrl}/wirecard_payment`, data);
  }

  wirecard_standalone_payment(data) {
    return this.http.post(`${this.baseUrl}/wirecard_standalone_payment`, data);
  }

  subscribe(data) {
    return this.http.post(`${this.baseUrl}/subscribe`, data);
  }

  agent_locations() {
    return this.http.get(`${this.baseUrl}/agent_locations`);
  }

  getElectricityBillRates(data) {
    return this.http.post(`${this.baseUrl}/electricity/rates`, data);
  }

  getMobileBillRates(data) {
    return this.http.post(`${this.baseUrl}/mobile/rates`, data);
  }

  meter_check(data) {
    return this.http.post(`${this.baseUrl}/check/meter`, data);
  }

  phone_check(data) {
    return this.http.post(`${this.baseUrl}/check/phone`, data);
  }

  get_electricity_recipient(data) {
    return this.http.post(`${this.baseUrl}/get/electricity/recipient`, data);
  }

  delete_bill_recipient(data) {
    return this.http.post(`${this.baseUrl}/delete/bill/recipient`, data);
  }

  get_mobile_recipient(data) {
    return this.http.post(`${this.baseUrl}/get/mobile/recipient`, data);
  }

  apply_bill_coupon(data) {
    return this.http.post(`${this.baseUrl}/apply/bill/coupon`, data);
  }

  create_bill_payment(data) {
    return this.http.post(`${this.baseUrl}/vend/cash/power`, data);
  }

  create_mobile_bill_payment(data) {
    return this.http.post(`${this.baseUrl}/credit/purchase`, data);
  }

  get_bill_payments(data) {
    return this.http.get(
      `${this.baseUrl}/bill/payments?page=` +
        data.page +
        `&date=` +
        data.date +
        `&service=` +
        data.service
    );
  }

  get_bill_payment_details(data) {
    return this.http.get(`${this.baseUrl}/bill/payment/detail/` + data);
  }

  // Acqiured

  getAcqiuredHash(data) {
    return this.http.post(`${this.baseUrl}/create/form/request`, data);
  }

  getAcqiuredPaymentLink(data) {
    return this.http.post(
      `${this.baseUrl}/create/form/request/payment/link`,
      data
    );
  }

  getVolumePaymentLink(data) {
    return this.http.post(`${this.baseUrl}/create/form/payment/link`, data);
  }

  remove_acqiured_card(data) {
    return this.http.post(`${this.baseUrl}/remove/card`, data);
  }

  campaign_unsubscribe(userId) {
    return this.http.get(`${this.baseUrl}/campaign/unsubscribe/${userId}`);
  }

  generate_session_url(data) {
    return this.http.post(`${this.baseUrl}/veriff/session/url`, data);
  }

  //wallet

  createWalletAccount() {
    return this.http.get(`${this.baseUrl}/register/wallet/user`);
  }

  getWalletBalance() {
    return this.http.get(`${this.baseUrl}/get/wallet/balance`);
  }

  getRewardWalletBalance() {
    return this.http.get(`${this.baseUrl}/get/reward-wallet/balance`);
  }

  getWalletByUUID(walletId: string) {
    return this.http.get(`${this.baseUrl}/get/${walletId}/wallet`);
  }

  getRewardWallet() {
    return this.http.get(`${this.baseUrl}/get/reward/wallet`);
  }

  getReceiverDetails(phoneNo) {
    return this.http.get(`${this.baseUrl}/get/user/by/phone/${phoneNo}`);
  }
  getSenderDetails(phoneNo) {
    return this.http.get(
      `${this.baseUrl}/get/user/by/phone/${phoneNo}/requestMoney`
    );
  }
  getWalletRecipientsList() {
    return this.http.get(`${this.baseUrl}/get/wallet/recipients`);
  }
  previewWalletTransaction(data) {
    return this.http.post(`${this.baseUrl}/preview/wallet/transaction`, data);
  }
  previewWithdrawTransaction(data) {
    return this.http.post(`${this.baseUrl}/preview/withdraw/transaction`, data);
  }
  previewExternalWalletTransaction(data) {
    return this.http.post(
      `${this.baseUrl}/preview/external/wallet/transaction`,
      data
    );
  }
  createWalletTransaction(data) {
    return this.http.post(`${this.baseUrl}/create/wallet/transaction`, data);
  }

  previewRequestMoney(data) {
    return this.http.post(`${this.baseUrl}/preview/request/money`, data);
  }
  requestWalletTransaction(data) {
    return this.http.post(`${this.baseUrl}/request/wallet/money`, data);
  }

  acceptWalletTransaction(paymentId) {
    return this.http.get(
      `${this.baseUrl}/accept/request/wallet/money/${paymentId}`
    );
  }

  rejectWalletTransaction(paymentId) {
    return this.http.get(
      `${this.baseUrl}/reject/request/wallet/money/${paymentId}`
    );
  }

  getWalletTransaction(pageNo, status, transactionType) {
    return this.http.get(
      `${this.baseUrl}/get/wallet/transactions?page=${pageNo}&status=${status}&type=${transactionType}`
    );
  }

  getWalletTransactionDetail(uuid) {
    return this.http.get(
      `${this.baseUrl}/get/wallet/transaction/details/${uuid}`
    );
  }
  getWalletTopupRates(data) {
    return this.http.post(`${this.baseUrl}/wallet/topup/rates`, data);
  }
  generateTrulayerToken(data) {
    return this.http.post(`${this.baseUrl}/generate/truelayer/token`, data);
  }
  createTrulayerSinglePayment(data) {
    return this.http.post(
      `${this.baseUrl}/truelayer/create/single/payment`,
      data
    );
  }
  generateStOrderId(data) {
    return this.http.post(
      `${this.baseUrl}/generate/secure/trading/token`,
      data
    );
  }
  generateacquiredHash(data) {
    return this.http.post(`${this.baseUrl}/generate/acquired/hash`, data);
  }
  createWalletTopup(data) {
    return this.http.post(`${this.baseUrl}/topup/wallet`, data);
  }
  withdrawalWalletMoneyatAgent(data) {
    return this.http.post(`${this.baseUrl}/wallet/withdrawal/money`, data);
  }
  withdrawalWalletMoneyatBank(data) {
    return this.http.post(`${this.baseUrl}/wallet/withdrawal/money/bank`, data);
  }
  send_phone_verification_otp(data) {
    return this.http.post(`${this.baseUrl}/send/otp`, data);
  }
  resend_phone_verification_otp(data) {
    return this.http.post(`${this.baseUrl}/resend/otp`, data);
  }
  verify_verification_otp(data) {
    return this.http.post(`${this.baseUrl}/verify/otp`, data);
  }
  getWalletDashboardOverview() {
    return this.http.get(`${this.baseUrl}/get/dashboard/overview`);
  }

  getAgentDetails(data) {
    return this.http.post(`${this.baseUrl}/get/agent/detail`, data);
  }

  getAgentDetailsByPhone(phone) {
    return this.http.get(`${this.baseUrl}/get/agent/by/phone/${phone}`);
  }

  getUserBanks() {
    return this.http.get(`${this.baseUrl}/get/banks`);
  }
  addUserBanks(data) {
    return this.http.post(`${this.baseUrl}/add/bank`, data);
  }
  removeUserBanks(id) {
    return this.http.get(`${this.baseUrl}/remove/${id}/bank`);
  }

  getQrCode() {
    return this.http.get(`${this.baseUrl}/get/qr/code`);
  }

  downloadQrCode() {
    return this.http.get(`${this.baseUrl}/download/qr/code`);
  }

  // pin setup
  setup_login_pin(data) {
    return this.http.post(`${this.baseUrl}/setup/login/pin`, data);
  }
  confirm_old_pin(data) {
    return this.http.post(`${this.baseUrl}/confirm/old/pin`, data);
  }
  confirm_pin(data) {
    return this.http.post(`${this.baseUrl}/confirm/pin`, data);
  }
  reset_login_pin(data) {
    return this.http.post(`${this.baseUrl}/reset/login/pin`, data);
  }
  disable_login_pin(data) {
    return this.http.post(`${this.baseUrl}/disable/login/pin`, data);
  }
  forgot_pin_general(data) {
    return this.http.post(`${this.baseUrl}/forgot/pin/general`, data);
  }
  forgot_pin_social(data) {
    return this.http.post(`${this.baseUrl}/forgot/pin/social`, data);
  }

  // sila

  plaidCreateLink(type) {
    return this.http.get(`${this.baseUrl}/plaid/create/link/${type}`);
  }

  machnetWidgetToken(transferType, type) {
    return this.http.get(
      `${this.baseUrl}/machnet/create/token/${transferType}/${type}`
    );
  }

  machnetKycWidgetToken(type) {
    return this.http.get(
      `${this.baseUrl}/machnet/genrate/${type}/widget/token`
    );
  }

  plaidTokenExchange(data) {
    return this.http.post(`${this.baseUrl}/plaid/token/exchange`, data);
  }

  getSilaAccounts() {
    return this.http.get(`${this.baseUrl}/sila/bank/accounts`);
  }

  getMachnetFundAccounts() {
    return this.http.get(`${this.baseUrl}/machnet/fund/accounts`);
  }

  checkForNewMachnetFundAccount(uuid) {
    return this.http.get(`${this.baseUrl}/check/machnet/fund/account/${uuid}`);
  }

  getSilaAccountBalance(accountId) {
    return this.http.get(`${this.baseUrl}/sila/bank/balance/${accountId}`);
  }

  createTransferWithSila(data) {
    return this.http.post(`${this.baseUrl}/transfer/pay/with/sila`, data);
  }

  createTransferWithMachnet(data) {
    return this.http.post(`${this.baseUrl}/transfer/pay/with/machnet`, data);
  }

  checkPaymnetByPaymentData(data) {
    return this.http.post(`${this.baseUrl}/transfer/pay/with/payment-id`, data);
  }

  getPage(page) {
    return this.http.get(`${this.baseUrl}/legal/${page}`);
  }

  generateTrulayerTokenBill(data) {
    return this.http.post(
      `${this.baseUrl}/truelayer/generate/token/bill`,
      data
    );
  }

  createTrulayerSinglePaymentBill(data) {
    return this.http.post(
      `${this.baseUrl}/truelayer/create/single/payment/bill`,
      data
    );
  }

  generateacquiredHashBill(data) {
    return this.http.post(`${this.baseUrl}/generate/acquired/hash/bill`, data);
  }
  generateStOrderIdBill(data) {
    return this.http.post(
      `${this.baseUrl}/generate/secure/trading/token/bill`,
      data
    );
  }
  resendVerifyEmail() {
    return this.http.get(`${this.baseUrl}/resend/verify/email`);
  }
  verifyEmailOTP(data) {
    return this.http.post(`${this.baseUrl}/verify/email/otp`, data);
  }
  updateEmail(data) {
    return this.http.post(`${this.baseUrl}/update/email`, data);
  }
}
