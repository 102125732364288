import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginService implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean |
  Observable<boolean> | Promise<boolean>
  {
    // throw new Error("Method not implemented.");
    // return this.Token.loggedIn();
    if (this.Token.loggedIn()) { return this.Token.loggedIn(); } 

    // go login page
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
  constructor(
    private Token: TokenService,
    private router: Router
  ) { }
}
