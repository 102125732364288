import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {


  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  public userDetails = JSON.parse(localStorage.getItem('userDetails'));

  constructor(
    private deviceService: DeviceDetectorService,
  ) { }

  public isMobile: boolean = this.deviceService.isMobile();
  ngOnInit() {

  }

}
