import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import {
  MatNativeDateModule,
  DateAdapter,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
// import { MatSliderModule } from '@angular/material/core';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTabsModule } from "@angular/material/tabs";
import {
  CommonModule,
  DatePipe,
  DecimalPipe,
  PathLocationStrategy,
} from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "./material.module";
import {
  Event as RouterEvent,
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { MatCardModule } from "@angular/material/card";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SlimLoadingBarModule } from "ng2-slim-loading-bar";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from "ngx-pagination";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { routes } from "./app.routes";
import { MalihuScrollbarModule } from "ngx-malihu-scrollbar";
import { ScrollbarDirective } from "./scrollbar.directive";
import { MatExpansionModule } from "@angular/material/expansion";
import { ClipboardModule } from "ngx-clipboard";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TextMaskModule } from "angular2-text-mask";
import "hammerjs";

import { AppComponent } from "./app.component";
import { IndexComponent } from "./index/index.component";
import { HomeComponent } from "./home/home.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { DownloadSectionComponent } from "./download-section/download-section.component";
import { HowItWorksComponent } from "./how-it-works/how-it-works.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { FaqComponent } from "./faq/faq.component";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClient,
} from "@angular/common/http";
import { ApiCallService } from "./Services/api-call.service";
import { TokenService } from "./Services/token.service";
import { TokenInterceptorService } from "./Services/token-interceptor.service";
import { NgxMaskModule } from "ngx-mask";
import { ScrollToModule } from "ng2-scroll-to-el";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
// import { ShorturlModule } from './modules/shorturl/shorturl.module';
// import { ShortUrlService } from 'angular-shorturl';
// import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angular-6-social-login";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angularx-social-login";

import { ToastrModule } from "ngx-toastr";
import { RateComparisionComponent } from "./rate-comparision/rate-comparision.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AgentLocationsComponent } from "./agent-locations/agent-locations.component";
import { AgmCoreModule } from "@agm/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from "ngx-cookie-service";
import { SidebarModule } from "ng-sidebar";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { CarouselComponent } from "./carousel/carousel.component";
import { CarouselItemDirective } from "./carousel/carousel-item.directive";
import { CarouselItemElementDirective } from "./carousel/carousel-item-element.directive";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CampaignUnsubscribeComponent } from "./campaign-unsubscribe/campaign-unsubscribe.component";
import {
  NgxUiLoaderConfig,
  NgxUiLoaderModule,
  PB_DIRECTION,
  POSITION,
  SPINNER,
} from "ngx-ui-loader";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { InputChange } from "./pipes/input-change.directve";
import { CurrencyFormatter } from "./pipes/currency-formatter-directive";
import { EmailConfirmationComponent } from "./email-confirmation/email-confirmation.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { NgOtpInputModule } from "ng-otp-input";
import { GooglePayButtonModule } from "@google-pay/button-angular";

export function getAuthServiceConfigs() {
  // let config = new AuthServiceConfig(
  //     [
  //       {
  //         id: FacebookLoginProvider.PROVIDER_ID,
  //         provider: new FacebookLoginProvider("293618867916447")
  //       },
  //       {
  //         id: GoogleLoginProvider.PROVIDER_ID,
  //         provider: new GoogleLoginProvider("210541462854-pqrv4j0vdub4c74l6bgg0buru8qqmp1f.apps.googleusercontent.com")
  //       },
  //     ]);
  // return config;
}
export function playerFactory() {
  return player;
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "red",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.threeBounce, // background spinner type
  fgsType: SPINNER.threeBounce, // foreground spinner type
  fgsColor: "#f94d1c", // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  hasProgressBar: false, // progress bar thickness
  text: "Please Wait...",
  textColor: "#FFFFFF",
  textPosition: "center-center",
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    DownloadSectionComponent,
    IndexComponent,
    ScrollbarDirective,
    HowItWorksComponent,
    ContactUsComponent,
    FaqComponent,
    RateComparisionComponent,
    NotFoundComponent,
    AgentLocationsComponent,
    InputChange,
    CurrencyFormatter,
    CarouselComponent,
    CarouselItemDirective,
    CarouselItemElementDirective,
    CampaignUnsubscribeComponent,
    SafeHtmlPipe,
    EmailConfirmationComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatNativeDateModule,
    // MatSliderModule,
    MatSidenavModule,
    CommonModule,
    MatButtonModule,
    SlimLoadingBarModule,
    MaterialModule,
    MatSlideToggleModule,
    MatCardModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    MatExpansionModule,
    MalihuScrollbarModule.forRoot(),
    RouterModule.forRoot(routes, {}),
    HttpClientModule,
    ClipboardModule,
    MatTooltipModule,
    GooglePlaceModule,
    TextMaskModule,
    NgxMaskModule.forRoot(),
    SocialLoginModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
    NgxCaptchaModule,
    ScrollToModule.forRoot(),
    SweetAlert2Module.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCnVk4yD6Q2m9MhGbCq7t3Pntd6JeguUVU",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SidebarModule.forRoot(),
    NgxMatSelectSearchModule,
    NgbModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ZXingScannerModule,
    SlickCarouselModule,
    LottieModule.forRoot({ player: playerFactory }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgOtpInputModule,
    GooglePayButtonModule,
  ],

  providers: [
    ApiCallService,
    TokenService,
    [DatePipe],
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    DecimalPipe,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "210541462854-pqrv4j0vdub4c74l6bgg0buru8qqmp1f.apps.googleusercontent.com"
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("293618867916447"),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    [
      {
        provide: LocationStrategy,
        useClass: PathLocationStrategy,
      },
    ],
    CookieService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
