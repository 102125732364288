import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TokenService } from './token.service';
import { HttpRequest } from 'selenium-webdriver/http/';

@Injectable({
  providedIn: 'root'
})
export class AuthServices {
  
  private loggedIn = new BehaviorSubject<boolean>(this.Token.loggedIn());
  authStatus = this.loggedIn.asObservable();
  
  
  cachedRequests: Array<HttpRequest> = [];

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  changeAuthStatus(value: boolean)
  {
    this.loggedIn.next(value);
  }

  constructor(
    private Token: TokenService
  ) { }
}
