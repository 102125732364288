<!-- [showBackdrop]="_showBackdrop" -->
<ng-sidebar [(opened)]="_opened" [mode]="_MODES" [closeOnClickBackdrop]="_closeOnClickBackdrop"
	[sidebarClass]="(_opened)?'ng-sidebar-top drop':'ng-sidebar-top'" position="right">
	<div class="sidebar-top-bar">
		<img (click)="_toggleSidebar()" class="toggle-sidebar-icon" src="{{ assetUrl }}images/icons/cancel_w.png"
			alt="">
	</div>
	<ul class="ng-sidebar-list-wrap">
		<!-- <li class="ng-sidebar-list" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">
			<a routerLink="/wallet/overview" (click)="_toggleSidebar()">Wallet</a>
		</li> -->
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/transfer/amount" (click)="_toggleSidebar()">Send Money</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/pay-bills/electricity" (click)="_toggleSidebar()">Cash Power</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/pay-bills/mobile" (click)="_toggleSidebar()">Airtime</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/agent-locations" (click)="_toggleSidebar()">Agent Locations</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/contact-us" (click)="_toggleSidebar()">Contact Us</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/help" (click)="_toggleSidebar()">Help</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active" *ngIf="loggedIn">
			<a (click)="_toggleSecondSidebar()">
				<img [src]="avatar">{{ userDetails?.first_name }}
				<img class="forward-arrow-sidebar" src="{{ assetUrl }}images/icons/back-arrow_w.png" alt="">
			</a>
		</li>
		<li class="ng-sidebar-list menu-button">
			<button routerLink="/auth/login" (click)="_toggleSidebar()" mat-flat-button class="bg-green" *ngIf="!loggedIn">Login</button>
		</li>
		<li class="ng-sidebar-list menu-button">
			<button routerLink="/auth/signup" (click)="_toggleSidebar()" mat-flat-button class="bg-blue btn-style"
				*ngIf="!loggedIn">Signup</button>
		</li>
	</ul>
</ng-sidebar>
<ng-sidebar [(opened)]="_opened_second_level" [mode]="_MODES" [closeOnClickBackdrop]="_closeOnClickBackdrop"
	[sidebarClass]="(_opened_second_level)?'ng-sidebar-second drop':'ng-sidebar-second'" position="right">
	<div class="sidebar-top-bar">
		<img (click)="_toggleSecondSidebar()" class="toggle-sidebar-icon"
			src="{{ assetUrl }}images/icons/back-arrow_w.png" alt="">
	</div>
	<ul class="ng-sidebar-list-wrap">
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/account/profile" (click)="_closeBothSidebar()">
				<app-my-profile-icon class="menu-svg-icon sidebar" width="24"
					primaryColor="#5a9f99"></app-my-profile-icon>
				My Profile
			</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/transfers" (click)="_closeBothSidebar()">
				<app-transfers-icon class="menu-svg-icon sidebar" width="24"
					primaryColor="#5a9f99"></app-transfers-icon>
				Transfers
			</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/recipients" (click)="_closeBothSidebar()">
				<app-recipients-icon class="menu-svg-icon sidebar" width="24"
					primaryColor="#5a9f99"></app-recipients-icon>
				Recipients
			</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/bills" (click)="_closeBothSidebar()">
				<app-bills-icon class="menu-svg-icon sidebar" width="24" primaryColor="#5a9f99"></app-bills-icon>
				Bill payments
			</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/limits" (click)="_closeBothSidebar()">
				<app-sending-limits-icon class="menu-svg-icon sidebar" width="24" primaryColor="#5a9f99"></app-sending-limits-icon>
				Sending Limits
			</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/refer-and-earn" (click)="_closeBothSidebar()">
				<app-refer-and-earn-icon class="menu-svg-icon sidebar" width="24"
					primaryColor="#5a9f99"></app-refer-and-earn-icon>
				Refer & Earn
			</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/support" (click)="_closeBothSidebar()">
				<app-support-icon class="menu-svg-icon sidebar" width="24" primaryColor="#5a9f99"></app-support-icon>
				Support
			</a>
		</li>
		<li class="ng-sidebar-list" routerLinkActive="active">
			<a routerLink="/settings" (click)="_closeBothSidebar()">
				<app-settings-icon class="menu-svg-icon sidebar" width="24" height="24"
					primaryColor="#5a9f99"></app-settings-icon>
				Setting
			</a>
		</li>
		<li class="ng-sidebar-list">
			<a href="#" (click)="logout($event)">
				<app-logout-icon class="menu-svg-icon sidebar" width="24" primaryColor="#5a9f99"></app-logout-icon>
				Logout
			</a>
		</li>
	</ul>
</ng-sidebar>

<div class="header">
	<nav class="navbar">
		<div class="container">
			<!-- Brand and toggle get grouped for better mobile display -->
			<div class="navbar-header">
				<button type="button" class="navbar-toggle collapsed" (click)="_toggleSidebar()">
					<span class="sr-only">Toggle navigation</span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<a class="navbar-brand" href=""><img src="{{ assetUrl }}images/icons/logo.svg" alt=""> </a>
			</div>

			<!-- Collect the nav links, forms,  and other content for toggling -->
			<div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
				<ul class="nav navbar-nav navbar-right">
					<!-- <li routerLinkActive="active">
						<a routerLink="/wallet/overview" (click)="_closeBothSidebar()">Wallet</a>
					</li> -->
					<li routerLinkActive="active">
						<a routerLink="/transfer/amount" (click)="_closeBothSidebar()">Send Money</a>
					</li>
					<li routerLinkActive="active">
						<a routerLink="/pay-bills/electricity" (click)="_closeBothSidebar()">Cash Power</a>
					</li>
					<li routerLinkActive="active">
						<a routerLink="/pay-bills/mobile" (click)="_closeBothSidebar()">Airtime</a>
					</li>
					<li routerLinkActive="active">
						<a routerLink="/agent-locations" (click)="_closeBothSidebar()">Agent Locations</a>
					</li>
					<li routerLinkActive="active">
						<a routerLink="/contact-us" (click)="_closeBothSidebar()">Contact Us</a>
					</li>
					<li routerLinkActive="active">
						<a routerLink="/help" (click)="_closeBothSidebar()">Help</a>
					</li>
					<li>
						<button routerLink="/auth/login" mat-flat-button class="bg-green" *ngIf="!loggedIn">Login</button>
					</li>
					<li>
						<button routerLink="/auth/signup" mat-flat-button class="bg-blue btn-style" *ngIf="!loggedIn">Signup</button>
					</li>
					<li class="dropdown">
						<a href="#" *ngIf="loggedIn" class="dropdown-toggle" data-toggle="dropdown" role="button"
							aria-haspopup="true" aria-expanded="false">
							<img [src]="avatar">{{ userDetails?.first_name }}
							<span class="caret"></span>
						</a>
						<ul class="dropdown-menu">
							<li>
								<a routerLink="/account/profile" (click)="_closeBothSidebar()">My Profile</a>
							</li>
							<li>
								<a routerLink="/transfers" (click)="_closeBothSidebar()">Transfers</a>
							</li>
							<li>
								<a routerLink="/recipients" (click)="_closeBothSidebar()">Recipients</a>
							</li>
							<li>
								<a routerLink="/bills" (click)="_closeBothSidebar()">Bill payments</a>
							</li>
							<li>
								<a routerLink="/limits" (click)="_closeBothSidebar()">Sending Limits</a>
							</li>
							<li>
								<a routerLink="/refer-and-earn" (click)="_closeBothSidebar()">Refer & Earn</a>
							</li>
							<li>
								<a routerLink="/support" (click)="_closeBothSidebar()">Support</a>
							</li>
							<li>
								<a routerLink="/settings" (click)="_closeBothSidebar()">Setting</a>
							</li>
							<li>
								<a href="#" (click)="logout($event)">Logout</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<!-- /.navbar-collapse -->
		</div>
		<!-- /.container-fluid -->
	</nav>
</div>
<div *ngIf="loggedIn">
	<div *ngIf="showKycBanner && showKycBannerOnRoute()" class="kyc-banner-wrapper">
		<div class="container">
			<div class="kyc-banner-inner">
				<p class="kyc-text">Please complete your account information for sending money.</p>
				<button class="kyc-button" type="button" routerLink="/account/verification/general-details">Complete Now</button>
			</div>
		</div>
	</div>
</div>