<section class="section trasfers">
  <div class="container">
    <div class="row">
      <div class="col-md-9">
        <router-outlet></router-outlet>
      </div>

      <div class="col-md-3">
        <mat-card class="profile-card" *ngIf="!isMobile">
          <div class="profile-card-header clearfix">
            <div class="image">
              <img [src]="userDetails.avatar" alt="">
            </div>
            <div class="box">
              <h4>{{ userDetails.first_name }} </h4>
              <span #tooltip="matTooltip" [matTooltip]="userDetails?.phone">{{ userDetails?.phone ? userDetails?.phone :
                '' }}</span>
            </div>
          </div>

          <app-profile-sidebar-menu></app-profile-sidebar-menu>
        </mat-card>
      </div>
    </div>
  </div>
</section>

<!-- recipients-section ends-->