import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UtilService {

  constructor() { }

  private appUrl = environment.appUrl;
  private backendUrl = environment.backendUrl;
  private isKYCBannerVisible = new BehaviorSubject<boolean>(true);

  get getIsKYCBannerVisible() {
    return this.isKYCBannerVisible.asObservable();
  }

  setIsKYCBannerVisible(value: boolean) {
    this.isKYCBannerVisible.next(value);
  }

  colorDepth(key : number): string {
    const data = {
      1: "ONE_BIT",
      2: "TWO_BITS",
      4: "FOUR_BITS",
      8: "EIGHT_BITS",
      15: "FIFTEEN_BITS",
      16: 'SIXTEEN_BITS',
      24: "TWENTY_FOUR_BITS",
      34: "THIRTY_TWO_BITS",
      48: 'FORTY_EIGHT_BITS'
    }
    return data[key]
  }
  
  get browserData() {
    const data = {
      "accept_header": "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8",
      "color_depth": this.colorDepth(screen.colorDepth),
      "java_enabled": "false",
      "javascript_enabled": "true",
      "language": navigator.language,
      "screen_height": screen.height,
      "screen_width": screen.width,
      "challenge_window_size": "WINDOWED_250X400",
      "timezone": (new Date().getTimezoneOffset()) / 60,
      "user_agent": navigator.userAgent
    }
    return data
  }
  

  get tdsData() {

    const data = {
      "action": "SCA",
      "source": "1",
      "type": "2",
      "preference": "0",
      "method_url_complete": "1",
      "browser_data": this.browserData,
      "merchant": {
        "contact_url": `${this.appUrl}contact-us`
      }
    }
    return data
  }
}
