<svg version="1.1"
    attr.height="{{ getHeight() }}"
    attr.width="{{ getWidth() }}"
    x="0px" 
    y="0px"    
	viewBox="0 0 64 64"
    style="enable-background:new 0 0 64 64;" 
    xml:space="preserve">
    <g>
        <path [ngStyle]="{'fill': getPrimaryColor()}" class="st0" d="M31.8,60.4c-5.3-0.1-10.7-0.7-15.9-2.7c-1.6-0.6-3.2-1.4-4.6-2.5c-2.3-1.7-3.5-4-3.2-6.9
            c0.4-6.1,2.7-11.3,6.9-15.6c0.6-0.6,1.4-0.9,2.2-0.7c0.8,0.2,1.4,0.8,1.6,1.6c0.2,0.9-0.2,1.6-0.8,2.3c-2.2,2.2-3.7,4.8-4.6,7.7
            c-0.5,1.7-0.8,3.5-0.9,5.3c-0.1,1.1,0.4,1.8,1.1,2.5c1.3,1.2,2.8,1.9,4.4,2.5c5.4,1.9,11,2.4,16.7,2.1c3.9-0.2,7.8-0.8,11.5-2.1
            c1.6-0.6,3.1-1.3,4.3-2.3c0.9-0.7,1.3-1.6,1.2-2.8c-0.3-5-2.1-9.3-5.6-12.9c-0.6-0.6-1-1.2-0.9-2c0.1-0.9,0.6-1.5,1.4-1.8
            c0.9-0.3,1.7-0.1,2.3,0.5c2.6,2.6,4.5,5.6,5.7,9c0.8,2.4,1.3,4.9,1.4,7.4c0.1,2.2-0.8,4-2.4,5.5c-2,1.9-4.4,3-7,3.8
            C41.9,59.7,37,60.3,31.8,60.4z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" class="st1" d="M32,3.6c-8.4,0-15.3,6.9-15.3,15.2c0,8.4,6.8,15.3,15.3,15.3c8.3,0,15.3-6.9,15.2-15.1
            C47.3,10.5,40.5,3.7,32,3.6z M32,29.8c-6,0-10.9-4.9-10.9-11C21.1,12.9,26,8,32,8c6,0,11,4.9,11,10.9C42.9,24.9,38,29.8,32,29.8z"
            />
    </g>
</svg>
