import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { AuthServices } from '../Services/auth.service';
import { ApiCallService } from '../Services/api-call.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @ViewChild('suscribeForm') suscribeForm;
  constructor(
    private Auth: AuthServices,
    private ApiCall: ApiCallService,
    private spinner: NgxSpinnerService,
    private el: ElementRef, 
    private renderer: Renderer2
    ) { }

  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  public success: string;
  public default_language: string;
  public error: string;
  public loggedIn: boolean;
  public data:any = {};

  ngOnInit() {    
    let lang = localStorage.getItem('language');
    this.default_language = lang;
    this.Auth.authStatus.subscribe(value => this.loggedIn = value);
  }
  public date = new Date();

  onMenuClick() {
    // this.renderer.addClass(this.el.nativeElement.querySelector('.navbar-collapse'), 'in');
  }
  
  onSubscribe(isValid: Boolean)
  {
    if (isValid){
      let submit_data = {
        email: this.data.suscribe_email,
        platform: 'Web',
      }
      this.ApiCall.subscribe(submit_data).subscribe(
        response => {
          this.suscribeForm.resetForm();
          this.success = response['message'];
          this.error = null;
        },
        error => {
          this.error = error.error.error;
          this.success = null;
        }
      );  

    }
  }

}
