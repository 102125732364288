import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private iss = {
    login : environment.apiUrl+'/login/social',
    social :environment.apiUrl+'/login',
    two_factor_login : environment.apiUrl+'/two-factor-login',
    verify_otp : environment.apiUrl+'/verify/phone',
    password_reset : environment.apiUrl+'/password/reset',
    signup_basicinfo : environment.apiUrl+'/signup/basic-info/',
    signup_basic_info : environment.apiUrl+'/signup/basic-info/*'
  }
  constructor() { }

  handle(token)
  {
    this.set(token);    
  }
  set(token)
  {
    localStorage.setItem('token', token);
  }

  get()
  {
    return localStorage.getItem('token');
  }

  remove()
  {
    localStorage.removeItem('token');
    localStorage.removeItem('user_details');
    sessionStorage.removeItem('send_money');
  }

  isValid()
  {
    const token = this.get();
    if(token)
    {
      // const payload = this.payload(token);      
      // if (payload) {        
      //   return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
      // }
      return true;
    }
    return false;
  }

  payload(token)
  {
    const payload =  token.split('.')[1];    
    return this.decode(payload);
  }

  decode(payload)
  {
    return JSON.parse(atob(payload));
  }

  loggedIn()
  {
    return this.isValid();
  }

}
