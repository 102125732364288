import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ApiCallService } from '../Services/api-call.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-campaign-unsubscribe',
  templateUrl: './campaign-unsubscribe.component.html',
  styleUrls: ['./campaign-unsubscribe.component.css']
})
export class CampaignUnsubscribeComponent implements OnInit {

  constructor(    
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,    
    private ApiCall:ApiCallService,
    private router: Router,
  ) { 

    this.route.queryParams.subscribe(
      data => this.handleQueryParamas(data)
      );
      
    }
    
  private userId = null;
  public buttonHidden = false;
  public assetUrl = environment.assetUrl;

  ngOnInit(): void {
  }

  handleQueryParamas(data)
  {
    this.userId = atob(data.uid)
  }

  unsubcribeCampaign(){
    this.spinner.show();
    this.ApiCall.campaign_unsubscribe(this.userId).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }
  handleResponse(data){
    this.buttonHidden = true;
    this.spinner.hide();
    
      Swal.fire('Unsubscribed',
        data.message,
        'success'
      )
      Swal.fire({
        title: 'Unsubscribed',
        text: data.message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Ok!'
      }).then((result) => {
        if (result.value) {          
          this.router.navigateByUrl('/index');
        }
      });
  }
  handleError(error){    
    this.buttonHidden = false;
    this.spinner.hide();
    Swal.fire('Error',
        error.error.message,
        'success'
      )
  }

}
