<div class="not_found_page">
  <div class="bg-section">
    <div class="container">
      <div class="bg-text not_found_wrap">
        <h1 class="">404</h1>
        <h3 class="">Page not Found</h3>
        <a routerLink="/">Home page <i class="fas fa-long-arrow-alt-right"></i></a>
      </div>
    </div>
  </div>

</div>