<svg version="1.1"
    attr.height="{{ getHeight() }}"
    attr.width="{{ getWidth() }}"
    x="0px" 
    y="0px"    
	viewBox="0 0 64 64"
    style="enable-background:new 0 0 64 64;" 
    xml:space="preserve">
    <g>
        <path [ngStyle]="{'fill': getPrimaryColor()}" d="M17.7,60c-0.6,0-1.2-0.3-1.7-0.8c-1.4-1.4-2.8-2.8-4.1-4.1c-0.6-0.6-1.1-1.1-1.7-1.7l-1.5-1.5
            c-1.3-1.3-2.7-2.7-4-4c-0.7-0.7-0.9-1.4-0.6-2.4C4.4,44.6,5,44.1,6,43.9c0.1,0,0.3,0,0.4,0c0.6,0,1.1,0.2,1.7,0.8l2.2,2.2
            c1.3,1.3,2.5,2.5,3.8,3.8c0,0,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1l0.3,0.4l0.9-0.5v-7.5c0-12.2,0-24.3,0-36.5c0-0.5,0-1,0.1-1.3
            C15.9,4.6,16.8,4,17.8,4c0.1,0,0.2,0,0.3,0c1,0.1,1.9,1,2,2.1c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0,0.2l0,44.1l0.5,0.1
            c0.1,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.7-0.4c0,0,0,0,0,0c0.8-0.7,1.5-1.5,2.2-2.2c1.2-1.2,2.5-2.5,3.8-3.8c0.5-0.5,1.1-0.7,1.7-0.7
            c1,0,1.9,0.7,2.2,1.7c0.2,0.9,0,1.7-0.7,2.4c-2.5,2.5-5.1,5.1-7.6,7.6l-2.8,2.8L20,58.7c-0.2,0.2-0.4,0.4-0.5,0.5
            C18.9,59.7,18.4,60,17.7,60z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" d="M46.2,59.9c-0.2,0-0.4,0-0.7-0.1c-0.9-0.3-1.6-1.1-1.6-2.1c0-0.2,0-0.4,0-0.6c0-0.1,0-0.1,0-0.2l0-44.1l-0.5,0
            c-0.5,0-0.7,0.3-0.8,0.4c0,0,0,0,0,0.1c-1,1-2,1.9-2.9,2.9c-1.1,1.1-2.1,2.1-3.2,3.2c-0.5,0.5-1,0.7-1.6,0.7
            c-0.3,0-0.6-0.1-0.9-0.2c-0.9-0.3-1.4-1-1.5-2c-0.1-0.7,0.1-1.3,0.7-1.8l1.1-1.1c3.4-3.4,6.9-6.9,10.4-10.4c0.5-0.5,1-0.7,1.6-0.7
            c0.6,0,1.2,0.3,1.7,0.7c3.6,3.6,7.4,7.4,11.3,11.3c0.9,0.9,1,2.3,0.1,3.3c-0.5,0.5-1,0.7-1.7,0.7c-0.6,0-1.2-0.3-1.7-0.7
            c-1.5-1.5-3-3-4.6-4.6c-0.7-0.7-1.3-1.3-2-2l-0.9-0.9l0,10.3c0,11.7,0,23.3,0,35c0,0.9-0.2,1.4-0.5,1.9
            C47.6,59.5,46.9,59.9,46.2,59.9z"/>
    </g>
</svg>