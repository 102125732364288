import { Component, OnInit } from '@angular/core';
import { ApiCallService } from '../Services/api-call.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PagerService } from '../Services/pager.service';

@Component({
  selector: 'app-agent-locations',
  templateUrl: './agent-locations.component.html',
  styleUrls: ['./agent-locations.component.css']
})
export class AgentLocationsComponent implements OnInit {

  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  public error = null;
  public success = null;
  public locations = null;
  public filteredLocations = null;
  public allLocations = null;
  public searchText:String = '';
  public textCleared:Boolean = true;
  public noData:Boolean = false;
  public pager: any = {};
  public pagedItems: any[];
  public pageLength: number = 50;

  constructor(
    private ApiCall: ApiCallService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public route: ActivatedRoute,    
    private pagerService: PagerService
  ) { }

  scrollTOElement = (element, offsetParam?, speedParam?) => {   
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  
  parseFloatString(s:string) {
    return parseFloat(s);
  }

  ngOnInit()
  {
    this.spinner.show();

      this.route.queryParams.subscribe(
        res => this.setProvider(res)
      );
      
      this.ApiCall.agent_locations().subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      );
  }

  setProvider(data){
    if (this.route.snapshot.queryParams['p']) {
      this.searchText = data.p
    }
  }

  handleResponse(data)
  {
    this.spinner.hide();
    this.allLocations = data;
    
    if(this.searchText != ""){    
      this.textCleared = false
      let data = this.allLocations
      let filteredData = data.filter(item => {
        return  item.provider.toLowerCase().includes(this.searchText.toLowerCase());
      });
      if(filteredData.length < 1){
        this.noData = true
      }
      this.locations = filteredData
      this.filteredLocations = filteredData
    }
    else{      
      this.locations = data;
      this.filteredLocations = data;
    }
    
    this.pager = this.pagerService.getPager(this.filteredLocations.length, 1 ,this.pageLength);    
    this.locations = this.filteredLocations.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
 
  handleError(error)
  {
    this.spinner.hide();
    this.error = error.error.error;
  }

  filterLocation(){
    var str = this.searchText;    
    if(str.length > 0){
        this.textCleared = false
        this.noData = false
        let data = this.allLocations
        
        const lowercasedFilter = str.toLowerCase();
        
        let filteredData = data.filter(item => {
          return  item.city.toLowerCase().includes(lowercasedFilter) 
                || item.name.toLowerCase().includes(lowercasedFilter)
                || item.provider.toLowerCase().includes(lowercasedFilter)
                || item.address.toLowerCase().includes(lowercasedFilter)
                || item.geo_location.toLowerCase().includes(lowercasedFilter);
        });
        if(filteredData.length < 1){
          this.noData = true
        }
      this.locations = filteredData
      this.filteredLocations = filteredData
    }
    else{
      this.noData = false;
      this.textCleared = true
      this.locations = this.allLocations;
      this.filteredLocations = this.allLocations;
    }
    
    this.pager = this.pagerService.getPager(this.filteredLocations.length, 1 ,this.pageLength);    
    this.locations = this.filteredLocations.slice(this.pager.startIndex, this.pager.endIndex + 1);
    
  }

  clearText()
  {
    this.noData = false;
    this.textCleared = true
    this.searchText = "";
    this.locations = this.allLocations;
    this.filteredLocations = this.allLocations;
    this.setPage(1);
  }
  
  setPage(page: number) 
  {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.filteredLocations.length, page ,this.pageLength);
    
    // get current page of items
    this.locations = this.filteredLocations.slice(this.pager.startIndex, this.pager.endIndex + 1);
    window.scrollTo({
      top: 400,
      behavior: 'smooth',
    });
    if(page > this.pager.totalPages)
    {
      return;
    }    
    
  }
}
