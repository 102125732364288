<footer class="section">
  <div class="container">
    <!-- <div class="newsletter">
      <div class="row">
        <div class="col-md-6">
          <h2>Subscribe for regular updates</h2>
          <p>Do you want to keep up to date with our new exciting products, services and promotions?</p>
        </div>
        <div class="col-md-6">
            <form #suscribeForm="ngForm" (ngSubmit)="onSubscribe(suscribeForm.valid)">
              <div class="subscribe-box text-right clearfix">
                <input type="text" class="form-control" [(ngModel)]="data.suscribe_email" class="form-control" name="suscribe_email" #suscribe_email="ngModel" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" [ngClass]="{'parsley-error':!suscribe_email.valid && (suscribe_email.touched || suscribeForm.submitted)}" placeholder="Email Address">
                <button type="submit" mat-flat-button class="subscribe-btn">SUBSCRIBE</button>
                <mat-error *ngIf="!suscribe_email.valid && (suscribe_email.touched || suscribeForm.submitted)" class="invalid-feedback">
                  <div class="home_error" *ngIf="suscribe_email.hasError('required')">Email is required.</div>
                  <div class="home_error" *ngIf="suscribe_email.hasError('pattern')">This value should be a valid email.</div>
                </mat-error> 
                <div class="notification-suscribe alert alert-danger" [hidden]='!error'>{{error}}</div>
                <div class="notification-suscribe alert alert-success" [hidden]='!success'>{{success}}</div>
              </div>
          </form>
        </div>
      </div>
    </div> -->

    <div class="footer-links">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-4">
              <h4 class="title">Company</h4>
              <ul class="links-list">
                <li>
                    <a routerLink="/legal/about-us" (click)="onMenuClick()">About Us</a>
                </li>
                <li>
                  <a target="__blank" href="https://www.pay365.cloud">Company</a>
                </li>
                <li>
                  <a href="#">News and Blogs</a>
                </li>
                <li>
                  <a routerLink="/agent-locations" (click)="onMenuClick()">Payout Locations</a>
                </li>
                <li>
                  <a href="#">Sitemap</a>
                </li>
              </ul>
            </div>

            <div class="col-md-4">
              <h4 class="title">Help & Support</h4>
              <ul class="links-list">
                <li>
                    <a routerLink="/legal/about-us" (click)="onMenuClick()">About Us</a>
                </li>
                <li>
                  <a href="#">News and Blogs</a>
                </li>
                <li>
                  <a routerLink="/help" (click)="onMenuClick()">Help</a>
                </li>
                <li>
                  <a routerLink="/contact-us" (click)="onMenuClick()">Contact Us</a>
                </li>
                <!-- <li>
                  <a routerLink="/price-chart" (click)="onMenuClick()">Fees</a>
                </li> -->
              </ul>
            </div>

            <div class="col-md-4">
              <h4 class="title">Legal</h4>
              <ul class="links-list">
                <li>
                  <a routerLink="/legal/term-and-conditions" (click)="onMenuClick()">Terms and Conditions
                  </a>
                </li>
                <li>
                  <a routerLink="/legal/privacy-policy" (click)="onMenuClick()">Privacy Policy</a>
                </li>
                <li>
                  <a routerLink="/legal/cookie-policy" (click)="onMenuClick()">Cookie Policy</a>
                </li>
                <!-- <li>
                  <a routerLink="/legal/disclaimer">Disclaimer</a>
                </li> -->
                <li>
                  <a routerLink="/legal/aml" (click)="onMenuClick()">Anti-Money Laundering</a>
                </li>
                <li>
                  <a href="https://machnetservices.com/golden-money-transfer/license-and-complaint" target="_blank">License and compliant</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-8">
              <h4 class="title">Quick Links</h4>
              <ul class="quick-links-list links-list clearfix">
                <li>
                    <a routerLink="/auth/signup" (click)="onMenuClick()" *ngIf="!loggedIn">Create Account</a>
                </li>
                <li>
                    <a routerLink="/auth/login" (click)="onMenuClick()" *ngIf="!loggedIn">Login</a>
                </li>
                <li>
                    <a routerLink="/transfer/amount" (click)="onMenuClick()" *ngIf="loggedIn">Send Money</a>
                </li>
                <!-- <li>
                  <a routerLink="/pay-bills/electricity" (click)="onMenuClick()">Utility Bills</a>
                </li> -->
                <li>
                  <a routerLink="/pay-bills/electricity" (click)="onMenuClick()">Cash Power</a>
                </li>
                <li>
                  <a routerLink="/pay-bills/mobile" (click)="onMenuClick()">Airtime</a>
                </li>
                <!-- <li>
                  <a routerLink="/pay-bills/electricity" (click)="onMenuClick()">Postpaid Recharge</a>
                </li>
                <li>
                  <a routerLink="/pay-bills/electricity" (click)="onMenuClick()">DTH Recharge</a>
                </li>
                <li>
                  <a routerLink="/pay-bills/electricity" (click)="onMenuClick()">Data Card</a>
                </li>
                <li>
                  <a routerLink="/pay-bills/electricity" (click)="onMenuClick()">Landline</a>
                </li> -->
              </ul>
            </div>

            <div class="col-md-4">
              <h4 class="title">Like & Follow Us</h4>
              <ul class="social-links">
                <li>
                  <a target="_blank" href="https://www.facebook.com/pingmoneyapp">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com/pingmoneyapp">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
              </ul>
              <img class="mt-2" src="{{ assetUrl }}images/general/pci-dss-compliant-badge.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

  <div class="footer-bottom text-center">
      
      <p>Ping Money is a trading name of Pay365 Limited which is registered and regulated by the Financial Conduct Authority under the Payment <br> Service Regulations 2017 for the provision of payment services (Financial Services Registration No: 799928)</p>
      <span>Copyright {{date| date:'yyyy'}} Ping Money. All Rights Reserved.</span>
    </div>
  </div>
</footer>
