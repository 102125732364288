import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HideHeaderService {

  private headerVisible = new BehaviorSubject<boolean>(true);
  constructor() { }
  
  get isHeaderVisible() {
    return this.headerVisible.asObservable();
  }
  
  setHeaderVisibility(value: boolean){
      this.headerVisible.next(value);
  }

} 