import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthServices } from 'src/app/Services/auth.service';
import { TokenService } from 'src/app/Services/token.service';

@Component({
  selector: 'app-profile-sidebar-menu',
  templateUrl: './profile-sidebar-menu.component.html',
  styleUrls: ['./profile-sidebar-menu.component.css']
})
export class ProfileSidebarMenuComponent implements OnInit {

  constructor(

    private Auth: AuthServices,
    private Token: TokenService,
    private router: Router) { }

  ngOnInit(): void {
  }

  logout(event: MouseEvent) {
    event.preventDefault();
    this.Token.remove();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/auth/login');
  }

}
