import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout-icon',
  templateUrl: './logout-icon.component.html',
  styleUrls: ['./logout-icon.component.css']
})
export class LogoutIconComponent implements OnInit {

  constructor() { }
  @Input() height: number = 22;
  @Input() width: number = 22;
  @Input() primaryColor: string = "#0d3848";
  @Input() secondaryColor: string = "#5a9f99";

  getPrimaryColor(): string {
    return this.primaryColor;
  }

  getSecondaryColor(): string {
    return this.secondaryColor;
  }

  getWidth(): number {
    return this.width;
  }

  getHeight(): number {
    return this.height;
  }

  getViewBox(): string {
    return `0 0 ${this.getWidth()} ${this.getHeight()}`;
  }

  getStyle(): string {
    return `enable-background:new 0 0 ${this.getWidth()} ${this.getHeight()};`;
  }

  ngOnInit(): void {
  }

}