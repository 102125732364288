<section class="section step2 recipients-section">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <mat-card class="card-style">

                    <div class="email-confirmation-section">
                        <div class="resend-email-section">
                            <img class="email-image" src="{{ assetUrl }}images/general/email-confirmation.png" alt="">

                            <ng-container *ngIf="!showEmailUpdate">
                                <h1 class="title">Email Confirmation</h1>
                                <p class="desc">{{ confirmationMessage }}</p>
                                <form [formGroup]="otpForm" class="pin-verify-form" (ngSubmit)="onSubmit()"
                                    autocomplete="off" novalidate>

                                    <div class="form-group mask-input text-center mt-2">
                                        <ng-otp-input (onInputChange)="onPinChange($event)"
                                            [config]="{length:4,inputClass:'pin-input',allowNumbersOnly:true}"></ng-otp-input>
                                    </div>

                                    <div class="text-center">
                                        <button mat-flat-button type="submit" class="theme-button">Verify OTP</button>
                                    </div>

                                    <div class="text-center mt-2 mb-2">No longer have access to the email ?<strong> <a
                                                (click)="onSetUpdateEmail()" class="text-primary pointer"> Update
                                                Email</a></strong>
                                    </div>
                                    <div class="text-center mt-2 mb-2">Did not received email?<strong> <a
                                                (click)="resendVerifyEmail()" class="text-primary pointer"> Resend
                                                Email</a></strong>
                                    </div>
                                </form>
                            </ng-container>

                            <ng-container *ngIf="showEmailUpdate">
                                <h1 class="title">Update Email</h1>
                                <p class="desc">{{ emailUpdateMessage }}</p>
                                <form [formGroup]="emailForm" class="pin-verify-form" (ngSubmit)="onEmailUpdate()"
                                    autocomplete="off" novalidate>

                                    <mat-form-field class="mt-2">
                                        <input matInput type="text" formControlName="email" placeholder="Email"
                                            [(ngModel)]="emailFormData.email">
                                        <mat-error *ngIf="submitted && emailFormControl.email.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="emailFormControl.email.errors.required">Email is required</div>
                                            <div *ngIf="emailFormControl.email.errors.email">This value should be a
                                                valid
                                                email</div>
                                            <div *ngIf="emailFormControl.email.errors?.serverError">
                                                {{ emailForm.get('email').errors?.serverError }}
                                            </div>
                                        </mat-error>
                                    </mat-form-field>
                                    <br>

                                    <div class="text-center">
                                        <button mat-flat-button type="submit" class="theme-button">Update Email</button>
                                    </div>

                                    <div class="text-center mt-2 mb-2"><strong>Received OTP in mailbox ? <a
                                                (click)="showEmailUpdate = false;setOtpValidations()"
                                                class="text-primary pointer">Go
                                                back</a></strong>
                                    </div>
                                </form>
                            </ng-container>

                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</section>