import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiCallService } from '../Services/api-call.service';
import { ScrollToService } from 'ng2-scroll-to-el';
import { environment } from 'src/environments/environment';

declare var $:any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})

export class FaqComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,    
    private ApiCall:ApiCallService,
    private scrollService: ScrollToService
  ) { }

  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  panelOpenState = true;
  public faq_list = [];
  error = null;

  replaceSpaceUscore(s:string) {
    return s.replace(/[^A-Z0-9]/ig, "_");
  }

  scrollTOElement = (element, offsetParam?, speedParam?) => {    
    document.getElementById(element).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  ngOnInit() {

    /** spinner starts on init */
    this.spinner.show();    

    $(window).scroll(function () {
      var scrollDistance = $(window).scrollTop();

      // Assign active class to nav links while scolling
      $('.faq-type').each(function (i) {
        if ($(this).position().top <= scrollDistance) {
          $('.profile-card ul li.active').removeClass('active');
          $('.profile-card ul li').eq(i).addClass('active');
        }
      });
    }).scroll();

    this.ApiCall.faq().subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }
  
  handleResponse(data)
  {
    this.spinner.hide();
    this.faq_list = data;    
  }

  handleError(error)
  {
    this.spinner.hide();
    this.error = error.error.error;
  }

}
