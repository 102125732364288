<div class="agent-locations">
  <div class="bg-section">
    <div class="container">
      <div class="bg-text">        
        <h1>Payout Locations</h1>
        <p>Collect your money from nearest payout point.</p>
        <a (click)="scrollTOElement(agentLocation)">
          <img src="{{ assetUrl }}images/icons/arrow-down.png" alt=""> </a>
      </div>
    </div>
  </div>

  <section class="agent-location-section section" id="agent-location" #agentLocation>
    <div class="container">
      <div class="row">

        <div class="col-md-12">
          <div class="agent-location-search-wrap">
              <input type="text" class="form-control" [(ngModel)]="searchText" (input)="filterLocation()" placeholder="Search for agent location">
              <i class="fa fa-search search-icon"></i>
              <i class="fa fa-times clear-icon pointer" *ngIf="!textCleared" (click)="clearText()"></i>
           </div>
        </div>
        <div *ngIf="!noData">
          <div class="col-md-12">
              <div class="location-wrapper table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th width="10%">City</th>
                      <th width="10%">Pay-out Agent</th>
                      <!-- <th width="15%">Provider</th> -->
                      <th width="30%">Address</th>
                      <th width="10%">Phone no</th>
                      <th width="25%">Opening hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let location of locations; let i = index">
                      <td>{{ location.city }}</td>
                      <!-- <td>{{ location.provider }}</td> -->
                      <td>{{ location.name }}</td>
                      <td class="google-location-wrap">{{ location.address }}
                        <span class="google-pin" *ngIf="location.latitude && location.longitude">
                          <a matTooltip="View on Goole Maps" href="https://maps.google.com/?q={{ location.latitude }},{{ location.longitude }}" target="_blank">
                            <img src="{{ assetUrl }}images/icons/google-pin.png" alt="">
                          </a>
                        </span>
                      </td>
                      <td>{{ location.phone }}</td>
                      <td>{{ location.opening_hours }}</td>
                    </tr> 
                  </tbody>
                </table>
                <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                  <li [ngClass]="{disabled:pager.currentPage === 1}">
                      <a (click)="setPage(1)">First</a>
                  </li>
                  <li [ngClass]="{disabled:pager.currentPage === 1}">
                      <a (click)="setPage(pager.currentPage - 1)">Previous</a>
                  </li>
                  <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                      <a (click)="setPage(page)">{{page}}</a>
                  </li>
                  <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                      <a (click)="setPage(pager.currentPage + 1)">Next</a>
                  </li>
                  <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                      <a (click)="setPage(pager.totalPages)">Last</a>
                  </li>
              </ul>
                <!-- <div class="location-map-holder" *ngIf="location.geo_location != 'N/A'">
                  <agm-map [zoomControl]="false" [fullscreenControl]="true" [minZoom]="15" [streetViewControl]="false" [latitude]="parseFloatString(location.latitude)" [longitude]="parseFloatString(location.longitude)">
                    <agm-marker [latitude]="parseFloatString(location.latitude)" [longitude]="parseFloatString(location.longitude)">
                        <agm-info-window>{{location.geo_location}}</agm-info-window>
                    </agm-marker>
                  </agm-map>
                </div>
                <div class="location-map-holder" *ngIf="location.geo_location == 'N/A'">
                  <img src="{{ assetUrl }}images/icons/location-na.png" alt="">
                </div>
                <div class="location-text-holder">
                    <h4 class="location-text-name">{{location.name}} </h4>
                    <h5 class="location-text-phone"><i class="fa fa-map-marker-alt color-green mr-1"></i>{{location.city}}</h5>
                    <h5 class="location-text-phone"><i class="fa fa-phone color-green mr-1"></i>{{location.phone}}</h5>
                    <h5 class="location-text-phone"><i class="fa fa-clock color-green mr-1"></i>{{location.opening_hours}}</h5>
                    <h5 class="location-text-address">{{location.address}}</h5>
                </div> -->
              </div>        
              <!-- <div class="clearfix" *ngIf="(i % 2) == 0"></div> -->
          </div>
        </div>
        <div *ngIf="noData">
          <h4 class="text-center">No location available with these keywords.</h4>
        </div>
      </div>
    </div>
  </section>

</div>