<section class="section download-section text-center">
  <div class="container">
    <h2 class="section-title">Send money on the go with the Ping App</h2>
    <div class="download-image">
      <a href="https://bit.ly/PingMoney" target="_blank">
        <img loading="lazy" src="{{ assetUrl }}images/general/app-store-img.png" alt="">
      </a>
      <a href="https://bit.ly/32m2Nrj" target="_blank">
          <img loading="lazy" src="{{ assetUrl }}images/general/googleplay-img.png" alt="">
      </a>
    </div>
  </div>
</section>