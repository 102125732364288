<section class="carousel-wrapper" [ngStyle]="carouselWrapperStyle">
  <ul class="carousel-inner" #carousel>
    <li *ngFor="let item of items ,let i = index" class="carousel-item" [ngClass]="{'focused-carousel-item': selectedItemClass === i}">
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </li>
  </ul>
</section>
<ng-container *ngIf="showControls">
  <button mat-icon-button class="carousel__prev-button" (click)="prev()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button class="carousel__next-button" (click)="next()">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</ng-container>