import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { ApiCallService } from "../Services/api-call.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-transfer-pay-verify",
  templateUrl: "./transfer-pay-verify.component.html",
  styleUrls: ["./transfer-pay-verify.component.css"],
})
export class TransferPayVerifyComponent implements OnInit {
  public assetUrl = environment.assetUrl;
  public apiLoading = true;
  public transaction: Record<string, any>;
  constructor(
    private route: ActivatedRoute,
    private ApiCall: ApiCallService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    const paramValue = this.route.snapshot.queryParamMap.get("payment_id");
    this.ApiCall.verifyTruelayerPayment({ payment_id: paramValue }).subscribe(
      (data: any) => {
        this.transaction = data.data;
        this.spinner.hide();
        this.apiLoading = false;
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.apiLoading = false;
      }
    );
    //
  }
}
