import { Injectable, VERSION } from '@angular/core';
import { TokenService } from './token.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {

  constructor(public Token: TokenService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.Token.loggedIn()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.Token.get()}`,
          "Device-Type": `Web`,
          "Device-App-Version": VERSION.full,
          'Device-Model': `Browser`,
          'Device-Imei': ``
        }
      });
    }
    else {
      request = request.clone({
        setHeaders: {
          "Device-Type": `Web`,
          "Device-App-Version": VERSION.full,
          'Device-Model': `Browser`,
          'Device-Imei': ``
        }
      });
    }

    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.router.navigateByUrl('/auth/login');
          this.Token.remove();
        }
      }
    });
  }
}
