import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiCallService } from 'src/app/Services/api-call.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvatarService } from 'src/app/Services/avatar.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnDestroy {

  @ViewChild('imageSelect') imageSelect: ElementRef;
  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  public imgSrc = this.assetUrl + 'images/general/man-img.png';
  public userDetails = JSON.parse(localStorage.getItem('userDetails'));
  public isMobile: boolean = this.deviceService.isMobile();
  public modalReference = null;
  public coordinates = { x1: 0, y1: 0, x2: 0, y2: 0 };
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public imageError = null;
  public error = null;
  public success = null;
  public canUploadImage: boolean = this.router.url.includes('/account/profile') ? true : false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private ApiCall: ApiCallService,
    private spinner: NgxSpinnerService,
    private AvatarService: AvatarService,
    private toastr: ToastrService,
    private deviceService: DeviceDetectorService
  ) {

    if (!this.userDetails?.isEmailVerified && this.userDetails?.country_iso_3166_2 != "GM") {
      this.router.navigateByUrl('email-confirmation')
    }
    this.imgSrc = this.userDetails.avatar;
  }

  ngOnInit() {
  }

  openModal(reference) {
    this.modalReference = this.modalService.open(reference, { ariaLabelledBy: 'modal-basic-title', size: 'md', backdrop: 'static' })
    this.imageChangedEvent = '';
  }

  closeModal() {
    if (this.modalReference != null) {
      this.modalReference.close();
    }
  }

  fileChangeEvent(event: any): void {
    $('.cropper').show();
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    this.imageError = "Some error occured while loading image, Please select again."
  }

  UploadProfilePic() {
    this.onfileSelected(this.croppedImage);
  }

  onfileSelected(image) {
    if (image === "") {

      this.showToast('error', 'Please select image to upload')
      return false;
    }
    this.spinner.show();
    var data = { file: image }

    this.ApiCall.update_profile_photo(data).subscribe(
      data => this.handleprofileResponse(data),
      error => this.handleprofileError(error)
    );
  }

  handleprofileResponse(data) {
    this.spinner.hide();
    this.error = null;
    this.imgSrc = data.avatar;
    this.imageChangedEvent = '';
    this.userDetails['avatar'] = this.imgSrc;
    localStorage.setItem("userDetails", JSON.stringify(this.userDetails));
    this.modalReference.close();
    this.AvatarService.setUser(data);
    // this.AvatarService.avatarChanged(data);
    this.success = "Profile photo has been updated successfully.";
  }

  handleprofileError(error) {
    this.spinner.hide();
    this.success = null;
    this.error = error.error.error;
  }

  showToast(type, message) {
    if (type === "success") {
      this.toastr.success(message, 'Success !', {
        closeButton: true,
        positionClass: 'toast-bottom-center',
      });
    }
    else {
      this.toastr.error(message, 'Error !', {
        closeButton: true,
        positionClass: 'toast-bottom-center',
      });
    }
  }

  ngOnDestroy() {
    if (this.modalReference) {
      this.closeModal();
    }
  }
}
