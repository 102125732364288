<!-- recipients-section starts-->
<section class="section recipients-section">
  <div class="container">
    <div class="row">
      <div class="col-md-9">
        <router-outlet></router-outlet>
      </div>

      <div class="col-md-3">
        <mat-card class="profile-card" *ngIf="!isMobile">
          <div class="profile-card-header clearfix">
            <div class="image">
              <img [src]="imgSrc" alt="">
            </div>
            <div class="box">
              <h4>{{ user_details?.first_name }}</h4>
              <span #tooltip="matTooltip" [matTooltip]="user_details?.phone">{{ user_details?.phone ?
                user_details?.phone : '' }}</span>
            </div>
          </div>
          <app-profile-sidebar-menu></app-profile-sidebar-menu>
        </mat-card>
      </div>
    </div>
  </div>
</section>

<!-- recipients-section ends-->