<section class="section legal-section">
  <div class="container">
    <div class="row">

      <div class="col-md-3 hidden-xs hidden-sm" id="getFixed">
        <mat-card class="profile-card">
          <div class="profile-card-header text-center">
            <h3>Legal</h3>
          </div>
          <ul class="legal-page-links">
            <li>
              <a routerLinkActive="is-active" routerLink="/legal/about-us"> About Us</a>
            </li>
            <li>
              <a routerLinkActive="is-active" routerLink="/legal/term-and-conditions"> Term-and-conditions</a>
            </li>

            <li>
              <a routerLinkActive="is-active" routerLink="/legal/privacy-policy"> Privacy Policy</a>
            </li>
            <li>
              <a routerLinkActive="is-active" routerLink="/legal/cookie-policy"> Cookie Policy</a>
            </li>
            <!-- <li>
              <a routerLink="/legal/disclaimer"> Disclaimer</a>
            </li>               -->
            <li>
              <a routerLinkActive="is-active" routerLink="/legal/aml"> Anti-Money Laundering Policy</a>
            </li>
            <li>
              <a href="#">
                Terms and Conditions - USA</a>
              <ul>
                <li><a target="_blank" href="https://machnetservices.com/golden-money-transfer/license-and-complaint">
                    License and compliant</a></li>
                <li><a target="_blank" href="https://machnetservices.com/ping-payments-llc">
                    Terms of use</a></li>
              </ul>
            </li>
          </ul>
        </mat-card>
      </div>

      <div class="col-md-9" style="float: right">
        <router-outlet></router-outlet>
      </div>

    </div>
  </div>
</section>