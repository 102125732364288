import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2'

import { CookieService } from 'ngx-cookie-service';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})


export class AppComponent implements OnInit {

  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  public cookieValue: string = 'true';
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private translate: TranslateService,
    private cookieService: CookieService
  ) {

    translate.setDefaultLang('en');
  }

  ngOnInit() {
    // this.spinner.hide();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
      this.cookieValue = this.cookieService.get('cookie_banner');
      if (this.cookieValue == '') {
        this.cookieValue = 'false';
      }
    });


    // preloader jquery
    $(window).on('load', function () { // makes sure the whole site is loaded 
      $('#status').fadeOut(); // will first fade out the loading animation 
      $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website. 
      $('body').delay(350).css({ 'overflow': 'visible' });
    })
    // preloader jquery

    // dropdown style
    $('.navbar .dropdown').hover(function () {
      $(this).find('.dropdown-menu').first().stop(true, true).delay(150).slideDown();
    }, function () {
      $(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp()
    });
    // dropdown style

    // backtotop-btn jquery
    $(window).scroll(function () {
      if ($(this).scrollTop()) {
        $('#toTop').fadeIn(400);
      } else {
        $('#toTop').fadeOut(400);
      }
    });

    $("#toTop").click(function () {
      $("html, body").animate({ scrollTop: 0 }, 1000);
      return false;
    });
    // backtotop-btn jquery

    
    // this.showAlert();
  }

  showAlert() {
    Swal.fire({
      html: this.swalHtml(),
      showCancelButton: false,
      confirmButtonText: 'Got It!',
      customClass: {
        popup: 'swal-notice-wrapper',
        confirmButton: 'swal-notice-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {

      }
    });
  }

  swalHtml() {
    return `
      <h4 class="title">Important Notice</h4>
      <p class="sub-title">Temporary Pause of Transfers from the United Kingdom due to Technical Issues. Sorry for any inconvenience caused. For further information, please contact our support team.</p>
      <p class="desc">Please Note: Our <strong>Airtime</strong> and <strong>Cash Power</strong> Services are Still Operational.</p>
    `
  }

  navigate(path: string) {
    return this.router.navigate([path])
  }

  setCookieBannerTrue(value: string) {
    this.cookieService.set('cookie_banner', value, 365);
    this.cookieValue = value;
  }
}
