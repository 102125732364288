<svg version="1.1"
    attr.height="{{ getHeight() }}"
    attr.width="{{ getWidth() }}"
    x="0px" 
    y="0px"    
	viewBox="0 0 64 64"
    style="enable-background:new 0 0 64 64;" 
    xml:space="preserve">
        <path [ngStyle]="{'fill': getPrimaryColor()}" d="M13.6,61.4l-0.2-0.1c-1.3-0.5-2-1.6-2-3.1c0-6.6,0-13.1,0-19.7c0-5,0-9.9,0-14.9c-2.7-1-4.6-3.2-5.2-5.9
            c-0.6-2.8,0.3-5.6,2.4-7.5c0.9-0.8,1.8-1.4,2.7-1.6c0-0.8,0-1.5,0-2.3l0-0.7c0-1.6,1.2-2.8,2.8-2.8S17,3.9,17,5.5l0,0.6
            c0,0.8,0,1.6,0,2.4c3.7,1.4,5.8,5,5.2,8.8c-0.5,3-2.3,5.2-5.2,6.3c0,5,0,10,0,15c0,6.5,0,13.1,0,19.6c0,1.5-0.7,2.6-2,3.1l-0.2,0.1
            H13.6z M14.3,13.5c-1.5,0-2.5,1-2.6,2.5c0,0.6,0.2,1.2,0.7,1.7c0.5,0.5,1.2,0.8,1.8,0.8h0c1.4,0,2.5-1.2,2.5-2.5
            c0-0.6-0.2-1.3-0.7-1.8C15.6,13.8,15,13.5,14.3,13.5l0-1L14.3,13.5z"/>
        <path [ngStyle]="{'fill': getPrimaryColor()}" d="M31.4,61.4l-0.2-0.1c-1.3-0.6-2-1.6-2-3.2c0-1.3,0-2.6,0-3.9c0-0.7,0-1.5,0-2.2c-3.8-1.5-5.8-4.9-5.2-8.9
            c0.5-2.9,2.2-5.1,5.1-6.2c0.1,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1c0-9.3,0-18.6,0-28l0-3.2c0-1.6,1.2-2.9,2.7-2.9
            c0.4,0,0.8,0.1,1.1,0.2c1.1,0.5,1.7,1.4,1.7,2.7c0,5.2,0,10.5,0,15.7l0,7.2c0,2.8,0,5.6,0,8.4c3.8,1.5,5.8,4.9,5.2,8.9
            c-0.5,3-2.3,5.2-5.2,6.3c0,0.9,0,1.8,0,2.7c0,1.1,0,2.3,0,3.4c0,1.5-0.6,2.6-2,3.2l-0.2,0.1H31.4z M32,41.9c-0.6,0-1.3,0.3-1.8,0.8
            c-0.5,0.5-0.8,1.1-0.8,1.8c0,1.4,1.2,2.5,2.5,2.5c1.3,0,2.5-1.2,2.5-2.5C34.6,43.1,33.4,41.9,32,41.9v-1V41.9z"/>
        <path [ngStyle]="{'fill': getPrimaryColor()}" d="M49.1,61.4l-0.2-0.1c-1.3-0.5-2-1.6-2-3.1c0-4.3,0-8.6,0-12.8c0-3.1,0-6.2,0-9.3c-2.7-1-4.6-3.2-5.2-5.9
            c-0.6-2.8,0.3-5.6,2.4-7.5c0.9-0.8,1.8-1.4,2.7-1.6c0-4.8,0-9.6,0-14.4l0-1.1c0-1.4,1-2.6,2.4-2.8c0.1,0,0.3,0,0.4,0
            c1.2,0,2.3,0.8,2.7,2c0.1,0.3,0.1,0.7,0.1,0.9V7c0,4.6,0,9.3,0,13.9c3.1,1.1,5.2,4,5.3,7.2c0.1,3.4-1.8,6.5-4.9,7.7
            c-0.5,0.2-0.5,0.2-0.5,0.9c0,3.7,0,7.5,0,11.2c0,3.4,0,6.8,0,10.3c0,1.5-0.7,2.6-2,3.1l-0.2,0.1H49.1z M49.7,25.9
            c-1.3,0-2.5,1.2-2.5,2.5c0,0.6,0.2,1.3,0.7,1.7c0.5,0.5,1.2,0.8,1.8,0.8c1.3,0,2.5-1.2,2.5-2.5C52.3,27.1,51.1,26,49.7,25.9v-1V25.9
            z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" d="M14.2,20.6c-1.2,0-2.4-0.6-3.3-1.5c-0.8-0.9-1.3-2-1.2-3.1c0.1-2.6,2-4.5,4.6-4.5c1.2,0,2.3,0.5,3.2,1.4
            c0.8,0.9,1.3,2,1.3,3.2C18.7,18.6,16.7,20.6,14.2,20.6L14.2,20.6z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" d="M32,49c-2.4,0-4.5-2.1-4.5-4.5c0-1.2,0.5-2.3,1.3-3.2c0.9-0.9,2-1.4,3.2-1.4c2.5,0,4.6,2.1,4.6,4.6
            C36.5,46.9,34.4,49,32,49L32,49z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" d="M49.7,33c-1.2,0-2.4-0.5-3.3-1.4c-0.9-0.9-1.3-2-1.3-3.2c0.1-2.5,2.1-4.5,4.5-4.5c2.6,0.1,4.6,2.1,4.6,4.6
            C54.3,30.9,52.2,33,49.7,33L49.7,33z"/>
</svg>
