<!-- faq starts-->
<section class="section faq">
  <div class="container">
    <div class="row">
      <div class="col-md-3 hidden-xs hidden-sm" id="getFixed" >
        <mat-card class="profile-card">
          <div class="profile-card-header text-center">
            <h3>FAQ Category</h3>
          </div>
          <ul>
            <li *ngFor="let faq of faq_list; let isFirst = first, let k = index" [class.active]="isFirst" (click)="scrollTOElement(replaceSpaceUscore(faq.category_name))">              
              <a>
                <img src="{{faq.icon}}" alt="">{{ faq.category_name }}</a>
            </li>
          </ul>
        </mat-card>
      </div>

      <div class="col-md-9" style="float: right">
        <mat-card class="">
          <div class="card-header">
            <mat-card-title>Frequently Asked Questions</mat-card-title>
          </div>

          <div class="card-body card-margin">
            <div class="faq-type" *ngFor="let faq of faq_list; let i = index" id="{{replaceSpaceUscore(faq.category_name)}}">
              <h3 class="color-green" id="{{faq.category_name}}">{{faq.category_name}}</h3>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let items of faq.items; let j = index">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="{{ assetUrl }}images/icons/faq-icon.png" alt=""> {{ items.title }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <p innerHTML="{{items.description}}"></p>

                </mat-expansion-panel>
                

              </mat-accordion>
            </div>
          </div>

        </mat-card>
      </div>

    </div>
  </div>
</section>
<!-- faq ends-->