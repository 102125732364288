import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wallet-nav-tabs',
  templateUrl: './wallet-nav-tabs.component.html',
  styleUrls: ['./wallet-nav-tabs.component.css']
})
export class WalletNavTabsComponent implements OnInit {

  constructor() { }

  public assetUrl = environment.assetUrl;
  public userDetails = JSON.parse(localStorage.getItem('userDetails'));
  public imgSrc = this.userDetails.avatar;

  ngOnInit(): void {
  }

}
