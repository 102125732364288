<mat-card class="profile-card">
    <div class="profile-card-header clearfix">
      <div class="image">
        <img [src]="imgSrc" alt="">
      </div>
      <div class="box">
        <h4>{{ userDetails?.first_name }}</h4>
        <span #tooltip="matTooltip" [matTooltip]="userDetails?.phone">{{ userDetails?.phone ? userDetails?.phone : '' }}</span>
      </div>
    </div>
    <ul class="wallet-nav">
        <li routerLinkActive="active">
            <a routerLink="/wallet/overview">
                <img src="{{ assetUrl }}images/icons/wallet/overview-outline.svg" alt="">
                Overview
            </a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/wallet/transactions">
                <img src="{{ assetUrl }}images/icons/wallet/transactions-outline.svg" alt="">
                Transactions
            </a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/wallet/send-money">
                <img src="{{ assetUrl }}images/icons/wallet/send-outline.svg" alt="">
                Send
            </a>
        </li>
        <!-- <li routerLinkActive="active">
            <a routerLink="/wallet/buy-electricity">
                <img src="{{ assetUrl }}images/icons/wallet/buy-electricity.svg" alt="">
                Cash Power
            </a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/wallet/buy-airtime">
                <img src="{{ assetUrl }}images/icons/wallet/buy-airtime.svg" alt="">
                Airtime
            </a>
        </li> -->
        <li routerLinkActive="active">
            <a routerLink="/wallet/request-money">
                <img src="{{ assetUrl }}images/icons/wallet/request-outline.svg" alt="">
                Request
            </a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/wallet/topup">
                <img src="{{ assetUrl }}images/icons/wallet/deposit-outline.svg" alt="">
                Add
            </a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/wallet/withdrawal-money">
                <img src="{{ assetUrl }}images/icons/wallet/withdrawal-outline.svg" alt="">
                Withdraw
            </a>
        </li>
    </ul>
  </mat-card>