<svg version="1.1"
    attr.height="{{ getHeight() }}"
    attr.width="{{ getWidth() }}"
    x="0px" 
    y="0px"    
	viewBox="0 0 64 64"
    style="enable-background:new 0 0 64 64;" 
    xml:space="preserve">
    <g>
        <path [ngStyle]="{'fill': getPrimaryColor()}" class="st0" d="M57.3,60L57.3,60c-1.4-0.9-2.6-1.9-3.7-2.8c-0.6-0.5-1.2-1-1.9-1.5c-1-0.8-2-1.6-3-2.4
            c-0.2-0.2-0.4-0.2-0.6-0.2c-4.7,0-9.4,0-14.2,0c-2.9,0-5.8,0-8.7,0c-3.6,0-6.5-2.2-7.3-5.7c-0.3-1.2-0.2-2.4-0.2-3.6
            c0.1-1,1-1.8,2.1-1.8c0,0,0.1,0,0.1,0c1.1,0,2,0.9,2,2c0,0.4,0,0.7,0,1.1c0,0.2,0,0.3,0,0.5c0,2,1.3,3.3,3.3,3.3
            c3.4,0,6.8,0,10.2,0c4.4,0,8.8,0,13.3,0c0.8,0,1.5,0.2,2.1,0.7c1.3,1,2.6,2.1,3.9,3.1c0.3,0.3,0.6,0.5,1,0.8c0-11.3,0-20,0-28.2
            c0-1.9-1.1-3.1-3.1-3.3c-1.3-0.1-2.2-1-2.1-2.2c0-1.2,1-2,2.2-2l0,0c3.5,0.1,6.4,2.7,7,6.1l0.1,0v34.3l0,0c-0.1,1-0.7,1.6-1.7,1.7
            l0,0H57.3z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" class="st0" d="M6.1,47.9c-1,0-1.8-0.7-2-1.6C4,46,4,45.7,4,45.5l0-2.9c0-10.2,0-20.8,0-31.2C4,7.9,6.2,5,9.7,4.2
            C10.4,4,11.1,4,11.6,4c2.7,0,5.4,0,8.1,0l7.2,0c1.5,0,7.6,0,9.1,0c2.1,0,4.1,0,6.2,0c3.6,0,6.4,2.3,7.2,5.7
            c0.1,0.5,0.2,1.1,0.2,1.7c0,2.7,0,5.3,0,8c0,4.1,0,8.2,0,12.3c0,4.3-3.2,7.5-7.5,7.5c-3.2,0-6.3,0-9.5,0c-5,0-10.1,0-15.1,0
            c-0.3,0-0.4,0.1-0.6,0.2c-2.2,1.8-4.5,3.7-6.6,5.5l-2.5,2.1c-0.3,0.2-0.6,0.5-0.9,0.6C6.7,47.9,6.4,47.9,6.1,47.9z M11.7,8.2
            c-2.2,0-3.5,1.2-3.5,3.5l0,29.6c0.5-0.4,0.9-0.8,1.3-1.1c1.8-1.5,3.5-2.9,5.2-4.3c0.6-0.5,1.3-0.8,2.2-0.8c4.8,0,9.6,0,14.3,0
            l10.7,0c2.2,0,3.5-1.2,3.5-3.4c0-6.6,0-13.3,0-19.9c0-2.2-1.2-3.4-3.5-3.4l-15.1,0L11.7,8.2z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" class="st0" d="M36.8,18.6l-7.8,0c-4,0-8.1,0-12.1,0l-0.2,0c-0.2,0-0.4,0-0.6,0c-1.1-0.2-1.8-1.2-1.7-2.3
            c0.1-1.1,1-1.9,2.1-1.9c1,0,1.9,0,2.9,0c0.6,0,3.5,0,3.5,0l9.8,0c1.5,0,2.9,0,4.4,0c0.8,0,1.5,0.4,1.9,1c0.4,0.6,0.5,1.3,0.2,1.9
            c-0.3,0.8-0.9,1.3-1.8,1.3c-0.1,0-0.2,0-0.3,0L36.8,18.6z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" class="st0" d="M19.3,25.5c-0.9,0-1.9,0-2.8,0c-0.7,0-1.4-0.3-1.8-0.9c-0.4-0.6-0.5-1.3-0.2-1.9c0.3-0.8,1.1-1.4,2.1-1.4
            c0.8,0,1.7,0,2.5,0l3.3,0c0.8,0,3.8,0,4.5,0c1.1,0,2.2,0,3.3,0c0.8,0,1.5,0.4,1.9,1c0.4,0.6,0.5,1.3,0.2,1.9
            c-0.3,0.8-1.1,1.3-2.1,1.3c-1,0-1.9,0-2.9,0l-4,0c-0.7,0-1.3,0-2,0C20.7,25.5,20,25.5,19.3,25.5z"/>
    </g>
</svg>