<svg version="1.1"
    attr.height="{{ getHeight() }}"
    attr.width="{{ getWidth() }}"
    x="0px" 
    y="0px"    
	viewBox="0 0 64 64"
    style="enable-background:new 0 0 64 64;" 
    xml:space="preserve">
    <g>
        <path [ngStyle]="{'fill': getPrimaryColor()}" d="M48.8,33.9c-0.2,0-0.4,0-0.6,0c-3.9,0-7.8,0-11.7,0c-1.2,0-1.9-0.6-2.2-1.6c-0.2-1.1,0.5-2.2,1.6-2.3
            c0.3,0,0.5,0,0.8,0c3.8,0,7.7,0,11.5,0c0.2,0,0.4,0,0.7,0c-0.8-0.8-1.5-1.5-2.2-2.2c-0.5-0.5-0.7-1-0.6-1.7
            c0.1-0.8,0.5-1.4,1.3-1.6c0.8-0.3,1.5-0.1,2.1,0.5c1,1,2,2,3,3c0.8,0.8,1.7,1.6,2.5,2.5c0.9,1,0.9,2.1,0,3
            c-1.8,1.8-3.5,3.6-5.3,5.3c-0.9,0.9-2.1,0.9-2.9,0.1c-0.8-0.8-0.8-2,0.1-3c0.7-0.7,1.3-1.3,2-1.9C48.8,34,48.8,34,48.8,33.9z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" d="M39.9,21.9c0,1,0.5,1.7,1.4,2c1.3,0.4,2.5-0.6,2.5-2.1c0-2.5,0-5,0-7.4c0-0.5,0-0.9-0.1-1.4
            c-0.6-2.8-2.9-4.7-5.9-4.7c-2.5,0-5,0-7.5,0c-0.4,0-0.5-0.1-0.7-0.5c-0.6-1.6-1.7-2.8-3.3-3.5c-0.5-0.2-1-0.3-1.6-0.5
            c-0.5,0-0.9,0-1.4,0c-0.5,0.1-0.9,0.3-1.4,0.4c-2.2,0.7-4.4,1.4-6.5,2.2c-0.9,0.3-1.8,0.6-2.7,0.9c-2.9,1-4.4,3-4.4,6
            c0,9.4,0,18.8,0,28.3c0,3,0,5.9,0,8.9c0,2.8,1.5,4.9,4.1,5.8c3.3,1.1,6.5,2.2,9.8,3.3c2,0.6,3.8,0.3,5.5-1c1-0.8,1.7-1.8,2.1-3.1
            c0.2,0,0.4,0,0.6,0c2.6,0,5.1,0,7.7,0c2.9-0.1,5.5-2.4,5.7-5.3c0.1-2.9,0.1-5.8,0-8.7c0-0.8-0.5-1.4-1.2-1.7
            c-1.3-0.5-2.6,0.4-2.6,1.8c0,2.6,0,5.1,0,7.7c0,1.5-0.8,2.3-2.3,2.3c-2.3,0-4.7,0-7,0c-0.2,0-0.3,0-0.5,0c0-13.2,0-26.2,0-39.4
            c0.2,0,0.4,0,0.6,0c2.3,0,4.5,0,6.8,0c1.7,0,2.4,0.7,2.4,2.4C39.8,17.1,39.8,19.5,39.9,21.9z M26.1,32c0,7.2,0,14.5,0,21.7
            c0,1.3-0.5,2.1-1.6,2.2c-0.4,0.1-0.9,0-1.3-0.1c-3.1-1-6.2-2.1-9.4-3.1c-1.1-0.4-1.6-1-1.6-2.2c0-6,0-12,0-18c0-6.3,0-12.6,0-18.9
            c0-1.5,0.4-2,1.8-2.4c3-1,6-2,9-3c1.7-0.6,3,0.5,3,2.2c0,3.6,0,7.2,0,10.9C26.1,24.8,26.1,28.4,26.1,32z"/>
    </g>
</svg>