<div class="row login-wrapper" *ngIf="!apiLoading">
  <div class="col-md-12 login-header">
    <div class="row">
      <div class="col-md-6 col-xs-6">
        <a routerLink="/"
          ><img
            src="{{ assetUrl }}images/icons/logo.svg"
            alt=""
            class="logo-img"
        /></a>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="transaction-wrapper">
      <div class="transaction-success-detail">
        <div class="success-img text-center">
          <img
            src="{{ assetUrl }}/images/general/success-animation.gif"
            alt=""
          />
        </div>
        <div class="relative">
          <h1 class="transaction-amount text-center">
            {{ transaction?.sending_amount }} {{ transaction?.from_country }}
          </h1>
          <h3 class="transaction-status text-center">
            {{ transaction?.from_country }} Payment Successful
          </h3>
        </div>
      </div>

      <p class="transaction-message">
        Your transaction is being proccessed by your bank. You will recieve a
        notification shortly.
      </p>

      <p class="transaction-date text-center mb-3">
        {{ transaction?.created_at }}
      </p>

      <div class="transaction-details">
        <div class="detail-row">
          <span class="detail-label">Fees</span>
          <span class="detail-value">{{ transaction?.fees }}</span>
        </div>
        <hr class="divider" />
        <div class="detail-row">
          <span class="detail-label">Reference</span>
          <span class="detail-value"> #{{ transaction?.ptn }}</span>
        </div>
      </div>

      <div
        class="text-center mt-3 text-primary"
        style="text-decoration: underline"
      >
        <a routerLink="/"
          >Go to Ping Money website
          <!-- <i class="fas fa-long-arrow-alt-right"></i> --></a
        >
      </div>
    </div>
  </div>
</div>
