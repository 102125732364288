<svg version="1.1"
    attr.height="{{ getHeight() }}"
    attr.width="{{ getWidth() }}"
    x="0px" 
    y="0px"    
	viewBox="0 0 64 64"
    style="enable-background:new 0 0 64 64;" 
    xml:space="preserve">
    <g>
        <path [ngStyle]="{'fill': getPrimaryColor()}" d="M26.6,58.2c-2-0.3-3.2-2-3.4-3.5c-0.1-0.6-0.2-1.2-0.3-1.7c0-0.3-0.1-0.5-0.1-0.8c-0.3-1.7-1.4-2.8-3.1-3
            c-0.1,0-0.2,0-0.3,0c-0.6,0-1.1,0.2-1.6,0.4c-0.3,0.1-0.5,0.2-0.8,0.3c-0.5,0.2-1.1,0.4-1.6,0.6c-0.5,0.2-1,0.2-1.4,0.2
            c-0.7,0-1.4-0.2-2-0.5c-0.6-0.4-1.1-0.9-1.5-1.5c-1.5-2.6-3-5.2-4.5-7.8c-1-1.9-0.7-3.8,1-5.2c0.3-0.3,0.6-0.5,1-0.8
            c0.4-0.4,0.9-0.7,1.3-1.1c0.8-0.7,1.2-1.6,1.2-2.6c0-1-0.5-1.9-1.3-2.6c-0.3-0.3-0.7-0.6-1-0.8c-0.4-0.3-0.8-0.7-1.2-1
            c-1.6-1.4-2-3.4-1-5.2c1.3-2.3,2.7-4.8,4.6-7.9c0.8-1.3,2-2,3.4-2c0.4,0,0.9,0.1,1.3,0.2c0.6,0.2,1.1,0.4,1.7,0.6
            c0.3,0.1,0.6,0.2,0.9,0.3c0.5,0.2,1,0.3,1.5,0.3c0.2,0,0.3,0,0.5,0c1.5-0.3,2.6-1.4,2.8-2.9l0.1-0.5c0.1-0.7,0.2-1.4,0.4-2.2
            c0.3-1.5,1-2.6,2.4-3.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.3,0,0.5-0.1h10.2c2,0.3,3.2,1.5,3.5,3.6c0.1,0.5,0.2,1,0.3,1.6
            c0,0.3,0.1,0.6,0.1,0.9c0.3,1.7,1.3,2.7,3,3c0.1,0,0.2,0,0.4,0c0.5,0,1-0.2,1.5-0.3c0.3-0.1,0.5-0.2,0.8-0.3
            c0.5-0.2,1.1-0.4,1.6-0.6c0.5-0.2,1-0.3,1.5-0.3c0.7,0,1.4,0.2,2,0.5c0.6,0.4,1.1,0.9,1.5,1.5l0.4,0.6c1.3,2.3,2.7,4.7,4.1,7.1
            c1.1,1.9,0.7,3.9-1,5.3c-0.4,0.3-0.8,0.7-1.2,1c-0.3,0.3-0.6,0.5-0.9,0.8c-0.8,0.7-1.3,1.6-1.3,2.6c0,1,0.4,1.9,1.2,2.6
            c0.4,0.4,0.8,0.7,1.2,1c0.3,0.3,0.7,0.6,1,0.9c1.6,1.4,2,3.4,1,5.2c-1.4,2.5-2.9,5.1-4.6,7.9c-0.8,1.3-2,2-3.5,2c0,0,0,0,0,0
            c-0.4,0-0.9-0.1-1.3-0.2c-0.6-0.2-1.2-0.4-1.7-0.6c-0.3-0.1-0.6-0.2-0.9-0.3c-0.5-0.2-1-0.3-1.6-0.3c-0.2,0-0.4,0-0.6,0.1
            c-1.4,0.3-2.4,1.3-2.7,2.8c-0.1,0.4-0.1,0.8-0.2,1.1c-0.1,0.5-0.2,1.1-0.3,1.6c-0.2,1.3-0.9,2.3-2,3c-0.4,0.3-1,0.4-1.5,0.5H26.6z
             M19.5,45.3c3.3,0,6.1,2.2,6.9,5.5c0.2,0.7,0.3,1.5,0.4,2.3c0,0.2,0.1,0.5,0.1,0.7c0.1,0.5,0.3,0.7,0.8,0.7c1.4,0,2.7,0,4.1,0
            c1.3,0,2.7,0,4,0c0,0,0,0,0,0c0.5,0,0.8-0.2,0.8-0.7c0.1-0.9,0.3-1.8,0.4-2.6c0.6-3.3,3.6-5.8,7-5.8c0.3,0,0.5,0,0.8,0
            c0.9,0.1,1.7,0.4,2.6,0.7c0.2,0.1,0.3,0.1,0.5,0.2c0.3,0.1,0.6,0.2,0.9,0.3l0.5,0.2l0,0c0,0,0.1,0,0.1,0c0.3,0,0.4-0.3,0.5-0.4
            c0,0,0,0,0-0.1c0.5-0.9,1-1.7,1.5-2.6c0.9-1.5,1.8-3,2.6-4.6c0.3-0.5,0-0.8-0.2-1c-0.5-0.4-1.1-0.9-1.6-1.3L52,36.7
            c-1.7-1.4-2.7-3.5-2.7-5.6c0-2.1,1-4.2,2.7-5.6c0.2-0.1,0.3-0.3,0.5-0.4c0.5-0.4,0.9-0.8,1.4-1.2c0.4-0.3,0.3-0.7,0.2-1
            c-1.3-2.3-2.7-4.7-4.1-7.2c-0.1-0.2-0.3-0.4-0.6-0.4c-0.1,0-0.2,0-0.4,0.1c-0.5,0.2-1.1,0.4-1.6,0.6c-0.2,0.1-0.4,0.1-0.5,0.2
            c-0.9,0.3-1.8,0.5-2.7,0.5c-3.4,0-6.2-2.2-7-5.5c-0.2-0.7-0.3-1.5-0.4-2.2c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.3-0.2-0.7-0.8-0.7
            c-1.3,0-2.7,0-4.1,0c-1.3,0-2.7,0-4.1,0h0c-0.5,0-0.7,0.2-0.8,0.7c-0.1,0.4-0.1,0.8-0.2,1.3c-0.1,0.3-0.1,0.6-0.2,1
            c-0.6,3.7-3.5,6.3-7.1,6.3c-0.5,0-1.1-0.1-1.6-0.2c-0.8-0.2-1.6-0.5-2.3-0.8c-0.3-0.1-0.7-0.3-1-0.4c-0.1,0-0.2-0.1-0.4-0.1
            c-0.4,0-0.5,0.3-0.6,0.4c-1.5,2.6-2.8,4.9-4.1,7.2c-0.3,0.5,0,0.8,0.2,1c0.5,0.4,1,0.8,1.5,1.2l0.4,0.4c1.7,1.4,2.7,3.5,2.7,5.6
            c0,2.2-1,4.2-2.7,5.6l-0.4,0.4c-0.5,0.4-1,0.8-1.5,1.2c-0.2,0.1-0.5,0.4-0.2,0.9c1.3,2.2,2.7,4.6,4.2,7.2c0.1,0.1,0.2,0.4,0.6,0.4
            c0.1,0,0.2,0,0.4-0.1c0.7-0.3,1.5-0.6,2.2-0.8l0.3-0.1C17.8,45.5,18.7,45.3,19.5,45.3z"/>
        <path [ngStyle]="{'fill': getSecondaryColor()}" d="M31.8,41.3c-5.6,0-10.2-4.6-10.2-10.2c0-2.7,1.1-5.3,3-7.3c1.9-1.9,4.5-3,7.2-3c2.7,0,5.3,1.1,7.3,3
            c1.9,2,3,4.5,3,7.2C42,36.7,37.4,41.3,31.8,41.3C31.8,41.3,31.8,41.3,31.8,41.3z M31.8,24.5c-3.6,0-6.5,2.9-6.6,6.5
            c0,1.7,0.7,3.4,1.9,4.7c1.2,1.3,2.9,2,4.6,2l0.1,0c3.5,0,6.5-3,6.6-6.6c0-1.7-0.7-3.4-1.9-4.7C35.2,25.2,33.6,24.5,31.8,24.5
            L31.8,24.5z"/>
    </g>
</svg>