import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent implements OnInit {

  
  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  
  
  scrollTOElement = (element, offsetParam?, speedParam?) => {   
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  
  constructor(
    private spinner: NgxSpinnerService,
    private Title: Title
    ) { }

  ngOnInit() {

    this.Title.setTitle("Ping Money | Send money to Gambia - Simply Moving Money");
      /** spinner starts on init */
      this.spinner.show();

      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 2000);

  }

}
