<div class="how-works">
    <div class="bg-section">
      <div class="container">
        <div class="bg-text">
          <h1>Price Chart</h1>
          <p>Price chart for sending money to Gambia</p>
          <a (click)="scrollTOElement('#working-steps',1000,1000)">
            <img src="{{ assetUrl }}images/icons/arrow-down.png" alt=""> </a>
        </div>
      </div>
    </div>
  
    <section class="working-steps section" id="working-steps">
      <div class="container">
          <div class="row">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <th>From</th>
                  <th>To</th>
                  <th>Fees</th>
                  <th>Duration</th>
                </thead>
                <tbody>
                  <tr *ngFor="let price of price_list?.price; let i = index">
                    <td>{{price.from}}</td>
                    <td>{{price.to}}</td>
                    <td>{{price.fees}}</td>
                    <td>{{price_list.duration}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>Want to send more than? Please call the Ping team on <a href="tel:03330509149">0333 050 9149</a></p>
          </div>
  
      </div>
    </section>
  
  </div>