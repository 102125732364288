import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingComponent implements OnInit {

    constructor(
        private deviceService: DeviceDetectorService,
    ) { }

    hide = true;
    public appUrl = environment.appUrl;
    public assetUrl = environment.assetUrl;
    public imgSrc = this.assetUrl + 'images/general/man-img.png';
    public userDetails = JSON.parse(localStorage.getItem('userDetails'));
    public isMobile: boolean = this.deviceService.isMobile();

    ngOnInit() {
    }


}
