import { Injectable, EventEmitter, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  constructor() { }
  public userReplaySubject = new ReplaySubject<AvatarService>(1);
  // @Output() valueChange = new EventEmitter();

  setUser(u: AvatarService){
    this.userReplaySubject.next(u);
  }

  // avatarChanged(user) {   
  //   this.valueChange.emit(user.avatar);
  // }

  // getAvatar() {   
  //   return this.valueChange;
  // }
}
