import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-bill-payments',
  templateUrl: './bill-payments.component.html',
  styleUrls: ['./bill-payments.component.css']
})
export class BillPaymentsComponent implements OnInit {

  public appUrl = environment.appUrl;
  public assetUrl = environment.assetUrl;
  public imgSrc = this.assetUrl + 'images/general/man-img.png';
  public user_details = JSON.parse(localStorage.getItem('userDetails'));
  public isMobile: boolean = this.deviceService.isMobile();

  constructor(
    private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.imgSrc = this.user_details.avatar;
  }

}
