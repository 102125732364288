<div class="how-works">
  <div class="bg-section">
    <div class="container">
      <div class="bg-text">
        <h1>How it works</h1>
        <p>Sending money to the Gambia with ping is easy, fast and secure.<br> Just follow these simple steps. </p>
        <a (click)="scrollTOElement(workingSteps)">
          <img src="{{ assetUrl }}images/icons/arrow-down.png" alt=""> </a>
      </div>
    </div>
  </div>

  <section class="working-steps section" id="working-steps" #workingSteps>
    <div class="container">
      <div class="step">
        <div class="row">
          <div class="col-sm-6">
            <div class="left">
              <img src="{{ assetUrl }}images/general/how-works-img1.png" alt="" class="img-responsive">
            </div>
          </div>

          <div class="col-sm-6">
            <div class="step-text">
              <h3>How much do you want to send?</h3>
              <p>You'll always see our low fees and exchange rates up front
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="step">
        <div class="row">
          <div class="col-sm-6 col-md-push-6">
            <div class="">
              <img src="{{ assetUrl }}images/general/how-works-img2.png" alt="" class="img-responsive">
            </div>
          </div>

          <div class="col-sm-6  col-md-pull-6">
            <div class="step-text left">
              <h3>Who are you sending to?</h3>
              <p>Add the details of the person you’re sending to or choose from a list of people you’ve sent to before
              </p>
            </div>
          </div>

        </div>
      </div>

      <div class="step">
        <div class="row">
          <div class="col-sm-6">
            <div class="left">
              <img src="{{ assetUrl }}images/general/how-works-img3.png" alt="" class="img-responsive">
            </div>
          </div>

          <div class="col-sm-6">
            <div class="step-text">
              <h3>Are you happy with the details?</h3>
              <p>Check that all the transfer details are correct.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="step">
        <div class="row">
          <div class="col-sm-6 col-md-push-6">
            <div class="">
              <img src="{{ assetUrl }}images/general/how-works-img4.png" alt="" class="img-responsive">
            </div>
          </div>

          <div class="col-sm-6 col-md-pull-6">
            <div class="step-text left">
              <h3>How would you like to pay?</h3>
              <p>Choose how to pay (bank account or debit/credit card), double check your amount and you're done!</p>
            </div>
          </div>

        </div>
      </div>


    </div>
  </section>

</div>